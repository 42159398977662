
import { Alert, Button, FormControl, InputLabel, LinearProgress, Select, Stack, TextField } from '@mui/material';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Constants, GlobalFunctions as GF } from '../../../GlobalFunctions';
import { getORDERS } from '../../../actions/PageActions';
import { closePopUp, openPopUp } from '../../../actions/PopUpActions';
import { Label } from '../../components/moduls/Input';
import { Pults } from './Consts';

const FieldGroups = {
    Simple: 'Общие поля',
    Additional: 'Дополнительно',
    Pults: 'Пульты'
}



function makeKords(SOMFY, DAUERHAFT, NOVO, NOVOmini, AQARA) {
    return { SOMFY, DAUERHAFT, NOVO, NOVOmini, AQARA }
}

function getWidth(value) {
    if (value == null) return 0
    value = (value * 1 / 1000) + ''

    let width = value.indexOf('.') != -1 ? value.substr(0, value.indexOf('.') + 2) * 1 : value * 1
    if (width == '' || width == null) return 0

    let smallWidth = Math.round(value * 1000) % 100
    if (smallWidth > 0)
        width += 0.1


    return width.toFixed(1)
}

function getWidthCoast(value, values) {
    let width = getWidth(value) * 1
    if (width == 0) return 0
    let result = values[width]
    if (result == null) {
        width = width * 1
        let keys = Object.keys(values)
        if (width <= keys[0] * 1)
            result = values[keys[0]]

        else if (width >= keys[keys.length - 1] * 1)
            result = values[keys[keys.length - 1]]
        else
            for (const key of keys) {

                if (key * 1 >= width) {
                    result = values[key]
                    break
                }
            }

    }

    return result.coast
}

const KordsCount = {
    '0.5': makeKords(2, 0, 0, 0, 2),
    '0.6': makeKords(2, 0, 0, 0, 2),
    '0.7': makeKords(2, 2, 2, 2, 2),
    '0.8': makeKords(3, 3, 3, 3, 3),
    '0.9': makeKords(3, 3, 3, 3, 3),

    '1.0': makeKords(3, 3, 3, 3, 3),
    '1.1': makeKords(3, 3, 3, 3, 3),
    '1.2': makeKords(4, 4, 4, 4, 4),
    '1.3': makeKords(4, 4, 4, 4, 4),
    '1.4': makeKords(4, 4, 4, 4, 4),
    '1.5': makeKords(4, 4, 4, 4, 4),
    '1.6': makeKords(5, 5, 5, 5, 5),
    '1.7': makeKords(5, 5, 5, 5, 5),
    '1.8': makeKords(5, 5, 5, 5, 5),
    '1.9': makeKords(5, 5, 5, 5, 5),

    '2.0': makeKords(6, 6, 6, 6, 6),
    '2.1': makeKords(6, 6, 6, 6, 6),
    '2.2': makeKords(6, 6, 6, 6, 6),
    '2.3': makeKords(6, 6, 6, 6, 6),
    '2.4': makeKords(7, 7, 7, 7, 7),
    '2.5': makeKords(7, 7, 7, 7, 7),
    '2.6': makeKords(7, 7, 7, 7, 7),
    '2.7': makeKords(7, 7, 7, 7, 7),
    '2.8': makeKords(8, 8, 8, 8, 8),
    '2.9': makeKords(8, 8, 8, 8, 8),

    '3.0': makeKords(8, 8, 8, 8, 8),
    '3.1': makeKords(8, 8, 8, 8, 8),
    '3.2': makeKords(9, 9, 9, 9, 9),
    '3.3': makeKords(9, 9, 9, 9, 9),
    '3.4': makeKords(9, 9, 9, 9, 9),
    '3.5': makeKords(9, 9, 9, 9, 9),
    '3.6': makeKords(10, 10, 10, 10, 10),
    '3.7': makeKords(10, 10, 10, 10, 10),
    '3.8': makeKords(10, 10, 10, 10, 10),
    '3.9': makeKords(10, 10, 10, 10, 10),

    '4.0': makeKords(11, 11, 11, 11, 11),
    '4.1': makeKords(11, 11, 11, 11, 11),
    '4.2': makeKords(11, 11, 11, 11, 11),
    '4.3': makeKords(11, 11, 11, 11, 11),
    '4.4': makeKords(12, 12, 12, 12, 12),
    '4.5': makeKords(12, 12, 12, 12, 12),
    '4.6': makeKords(12, 12, 12, 12, 12),
    '4.7': makeKords(12, 12, 12, 12, 12),
    '4.8': makeKords(13, 13, 13, 13, 13),
    '4.9': makeKords(13, 13, 13, 13, 13),

    '5.0': makeKords(13, 13, 13, 13, 13),

}






class Index extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            Self: {},
            Order: {},
            errorMSG: null,
            fields: {},
            selected: {},
            result: {
                'Количество карнизов (шт)': 1
            },
            nowLoading: 3,
            errorFields: [],
            errorFieldsDescr: {}
        }
        this.inputFields = {}
        this.canSave = true
        this.nowGetingData = false

        this.GETs = GF.urlGETparams()
        this.ID_ORDER_ITEM = this.GETs.get('ID_ORDER_ITEM')
        this.ID_ORDER = this.GETs.get('ID_ORDER')
        this.USER_TOKEN = this.GETs.get('USER_TOKEN')
        this.ID_USER = this.GETs.get('ID_USER')
        this.isManager = (this.GETs.get('IS_MNGR') || 0) * 1 == 1
        this.recalc = this.GETs.get('recalc')

        if (this.ID_ORDER_ITEM == null && props.ID_ORDER_ITEM != null) this.ID_ORDER_ITEM = props.ID_ORDER_ITEM
        if (this.ID_ORDER == null && props.ID_ORDER != null) this.ID_ORDER = props.ID_ORDER

        this.lastBrend = -1
        this.input = []
        this.parentPath = []




        this.functionFields = (props) => {
            let Values = {}
            let steped = 0

            const defaultProps = {
                step: 0.1,
                max: 5,
                min: 0.1
            }

            props = {
                ...defaultProps,
                ...props
            }

            let priceNull = props.startCost - (props.start - props.min) / props.step * props.stepCost

            for (
                let i = props.min == null ? props.step : props.min;
                i <= props.max;
                i += props.step
            ) {
                Values[(i).toFixed(1)] = {
                    coast: priceNull + props.stepCost * steped
                }
                steped++
            }

            let result = {}
            result[props.title] = {
                values: Values
            }

            return result

        }




        this.functionFields_math = (props) => {
            let current = props.start
            let values = {}

            let childFields = props.childFields == null ? null : () => props.childFields()
            let onSelect = props.onSelect == null ? null : () => props.onSelect()
            let onShow = props.onShow == null ? null : (props) => props.onShow(props)

            let result = {}
            result[props.title] = {}

            let steped = 0
            for (let i = props.min; i <= props.max; i += props.step == null ? 0.1 : props.step) {
                if (i == props.min) {
                    values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)] = {
                        coast: props.start
                    }

                } else {
                    let next = props.func(current, steped, i.toFixed(props.toFixed == null ? 1 : props.toFixed))
                    values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)] = {
                        coast: next,
                    }
                    current = next

                }

                if (childFields != null)
                    values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)].childFields = childFields((i).toFixed(props.toFixed == null ? 1 : props.toFixed))

                if (onShow != null)
                    result[props.title].onShow = onShow

                if (onSelect != null)
                    values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)].onSelect = (key, value, callBack) => onSelect(props.title, (i).toFixed(props.toFixed == null ? 1 : props.toFixed), callBack)
                steped++
            }


            result[props.title].values = { ...values }
            return result
        }


        this.GnutieDugovoe = (coast_preOne) => {
            return ({
                'Тип гибки': {
                    group: FieldGroups.Additional,
                    onShow: (props) => {
                        return (new Promise((resolve, reject) => {

                            let needUpdate = false

                            let fild = { ...{ ...this.state.fields } }
                            let res = { ...{ ...this.state.result } }

                            if (props.changedField == 'Бренд') {
                                if (res['Бренд'] == 'SOMFY') {
                                    fild['Тип гибки'].values = {
                                        'Дуговое гнутие': {
                                            coast: 0
                                        },
                                    }
                                    if (res['Тип гибки'] == 'Загиб угла') {
                                        delete (res['Тип гибки'])
                                        delete (res['_Тип гибки'])
                                        delete (res['Количество загибов(шт)'])
                                        delete (res['_Количество загибов(шт)'])
                                        delete (res['Угловой элемент (тип)'])
                                        delete (res['_Угловой элемент (тип)'])
                                        delete (res['Угловой элемент (шт)'])
                                        delete (res['_Угловой элемент (шт)'])
                                        try {
                                            fild['Угловой элемент (тип)'].disabled = false
                                            fild['Угловой элемент (шт)'].disabled = false
                                        } catch { }
                                    }

                                } else {
                                    fild['Тип гибки'].values = {
                                        'Дуговое гнутие': {
                                            coast: 0
                                        },
                                        'Загиб угла': {
                                            coast: 0,
                                            childFields: {
                                                ...Gibka(coast_preOne)
                                            }
                                        }
                                    }
                                }

                                needUpdate = true
                            }


                            let width = this.state.result['Длина (мм)'] == null ? null : this.state.result['Длина (мм)'] * 1
                            width = width / 1000
                            if (this.state.result['Тип карниза'] == 'Зимний сад')
                                width = width * 2

                            if (props.changedField != 'Длина (мм)' || width == null) {

                            } else {
                                let k = this.state.result['Вид карниза'] == 'Ручной' ? this.props.TEXTS['Дуговое гнутие - Ручные карнизы'].TEXT : this.props.TEXTS['Дуговое гнутие - Мото карнизы раздвижные'].TEXT

                                fild['Тип гибки'].values['Дуговое гнутие'].coast = width * k

                                if (res['Тип гибки'] == 'Дуговое гнутие')
                                    res['_Тип гибки'] = width * k

                                needUpdate = true
                            }


                            if (needUpdate)
                                this.setState({ fields: {}, result: {} }, () =>
                                    this.setState({ fields: { ...fild }, result: { ...res } }, resolve))
                            else {
                                resolve()
                            }
                        }))

                    },
                    values: {
                        'Дуговое гнутие': {
                            coast: 0
                        },
                        'Загиб угла': {
                            coast: 0,
                            childFields: {
                                ...Gibka(coast_preOne)
                            }
                        }
                    }
                }


            })
        }



        let SOMFY_MOTORS_Light = () => {
            return {
                'Move Lite RTS (радио+сухие)': { coast: this.props.TEXTS['Move Lite RTS (радио+сухие)'].TEXT },
                'Move Lite WT (фазное)': { coast: this.props.TEXTS['Move Lite WT (фазное)'].TEXT },
                'Move Lite DCT (сухие)': { coast: this.props.TEXTS['Move Lite DCT (сухие)'].TEXT },
                'Move Lite WF (аккумуляторный)': {
                    coast: this.props.TEXTS['Move Lite WF (аккумуляторный)'].TEXT,
                    childFields: {
                        'Зарядное устройство': {
                            values: {
                                'Да': { coast: this.props.TEXTS['Move Lite WF (аккумуляторный) - Зарядное устройство'].TEXT },
                                'Нет': { coast: 0 },
                            }
                        },
                    }
                },
            }
        }


        let SOMFY_MOTORS_OTHER = () => {
            return {
                'Glydea Ultra 60 RTS (радио+сухие)': { coast: this.props.TEXTS['Glydea Ultra 60 RTS (радио+сухие)'].TEXT },
                'Glydea Ultra 60 WT (фазное)': { coast: this.props.TEXTS['Glydea Ultra 60 WT (фазное)'].TEXT },
                'Glydea Ultra 60 DCT (сухие)': { coast: this.props.TEXTS['Glydea Ultra 60 DCT (сухие)'].TEXT },
                'Irismo (аккумуляторный)': {
                    coast: this.props.TEXTS['Irismo (аккумуляторный)'].TEXT,
                    childFields: {
                        'Зарядное устройство': {
                            values: {
                                'Да': { coast: this.props.TEXTS['Irismo (аккумуляторный) - Зарядное устройство'].TEXT },
                                'Нет': { coast: 0 },
                            }
                        },


                    }
                }
            }
        }

        let Gibka = (coast_preOne = 2000) => {
            let field = this.functionFields_math({
                title: 'Количество загибов (шт)',
                start: 0,
                min: 0,
                max: 10,
                step: 1,
                toFixed: 0,
                func: (prev, step, i) => prev + coast_preOne
            })
            field['Количество загибов (шт)'].group = FieldGroups.Additional
            return field
        }


        let fieldSetting = (title, props, type) => {
            let data = {}
            data[title] = {
                values: {}
            }
            props.forEach(v => {
                if (type == 'раздвижки бесшумная' || type == 'раздвижки стандартная' || type == 'раздвижки лайт') {
                    data[title].values[v[0]] = {
                        coast: type == 'раздвижки бесшумная' ? 0 : -v[1],
                        childFields: {
                            'Количество раздвижек': {
                                group: FieldGroups.Additional,
                                onShow: (props) => {
                                    return new Promise((resolve, reject) => {
                                        let res = { ...this.state.result }
                                        let fild = { ...this.state.fields }


                                        if (this.state.result['Количество раздвижек'] == null) {
                                            res['Количество раздвижек'] = 1
                                            res['_Количество раздвижек'] = v[1] + (v[0] == 'От центра' ? v[1] : 0)
                                            this.setState({ fields: {}, result: {} }, () => {
                                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                            })
                                        } else resolve()
                                    })
                                },
                                values: {
                                    1: { coast: v[1] + (v[0] == 'От центра' ? v[1] : 0) },
                                    2: { coast: v[1] * 2 + (v[0] == 'От центра' ? v[1] * 2 : 0) },
                                    3: { coast: v[1] * 3 + (v[0] == 'От центра' ? v[1] * 3 : 0) },
                                }
                            }
                        }
                    }
                } else
                    data[title].values[v[0]] = { coast: v[1] }
            })

            return data
        }

        var Kreplenie = () => {
            return {
                'Крепление': {
                    group: FieldGroups.Additional,
                    values: {
                        "В потолок": {},
                        "В стену": {
                            childFields: {
                                "Стеновой кронштейн": {
                                    group: FieldGroups.Additional,
                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            if (props.changedField != 'Длина (мм)' && props.changedField != 'Крепление' && props.changedField != 'Стеновой кронштейн') {
                                                resolve()
                                            } else {
                                                let value = this.state.result['Длина (мм)']
                                                if (value != null && value != '') {
                                                    value = value / 1000
                                                    let kransh = this.state.fields['Стеновой кронштейн']
                                                    if (kransh == null || kransh.values == null) {
                                                        resolve()
                                                    } else {

                                                        let lenght = Math.floor(value * 1 / 0.5)
                                                        let count = lenght
                                                        if (value * 1 / 0.5 != lenght)
                                                            count++

                                                        if (count <= 0) count = 1

                                                        let selectedKransh = this.state.result['Стеновой кронштейн']

                                                        kransh = GF.ObjectClone(kransh.values)
                                                        Object.keys(kransh).forEach(key => {
                                                            kransh[key].coast = kransh[key].coast_preOne * count
                                                        })

                                                        let newCoast = null

                                                        if (selectedKransh != null && selectedKransh != '') {

                                                            Object.keys(kransh).forEach(key => {
                                                                if (key == selectedKransh)
                                                                    newCoast = kransh[key].coast
                                                            })
                                                        }

                                                        let newData = {
                                                            fields: {
                                                                ...this.state.fields,
                                                                'Стеновой кронштейн': {
                                                                    group: FieldGroups.Additional,
                                                                    ...this.state.fields['Стеновой кронштейн'],
                                                                    values: kransh
                                                                }
                                                            }
                                                        }

                                                        newData.result = { ...this.state.result }

                                                        if (newCoast != null)
                                                            newData.result['_Стеновой кронштейн'] = newCoast

                                                        newData.result['Количество стеновых кронштейнов'] = count + ''

                                                        this.setState({
                                                            fields: {},
                                                            result: {}
                                                        }, () => {
                                                            this.setState(newData, () => resolve())
                                                        })

                                                    }
                                                } else {
                                                    resolve()
                                                }
                                            }

                                        })
                                    },
                                    values: {
                                        '150x50': { coast_preOne: this.props.TEXTS['Кронштейн стеновой 150x50'].TEXT },
                                        '200x60': { coast_preOne: this.props.TEXTS['Кронштейн стеновой 200x60'].TEXT },
                                        '32см (усиленный)': { coast_preOne: this.props.TEXTS['Кронштейн стеновой 32 см (усиленный)'].TEXT },
                                    }
                                },
                                "Количество стеновых кронштейнов": {
                                    disabled: true,
                                    MathCoast: v => 0,
                                    type: 'number',
                                }
                            }
                        }
                    },
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            let fild = { ...this.state.fields }
                            let res = { ...this.state.result }

                            if (res['Крепление'] == null && this.state.nowLoading == 0) {
                                res['Крепление'] = 'В потолок'
                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => {
                                        resolve()
                                    })
                                })
                            } else {
                                resolve()
                            }
                        })
                    }
                }
            }
        }

        var DopGlaider = (MathCoast) => {
            return {
                'Дополнительный глайдер': {
                    group: FieldGroups.Additional,
                    type: 'number',
                    MathCoast,
                }
            }
        }

        var Ytajelitel = () => {
            return {
                'Утяжелитель': {
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            let fild = { ...this.state.fields }
                            let res = { ...this.state.result }


                            if ((props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') && fild['Утяжелитель'] != null) {
                                let length = res['Длина (мм)']
                                if (length == null || length == '') {
                                    fild['Утяжелитель'].values['Да'].coast = 0
                                } else {
                                    length = length / 1000
                                    fild['Утяжелитель'].values['Да'].coast = this.props.TEXTS['Утяжелитель'].TEXT * length
                                }

                                if (res['Утяжелитель'] == 'Да') {
                                    res['_Утяжелитель'] = fild['Утяжелитель'].values['Да'].coast =
                                        length == null || length == '' ? 0 : this.props.TEXTS['Утяжелитель'].TEXT * length
                                }

                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                            } else resolve()
                        })

                    },
                    values: {
                        'Да': { coast: this.props.TEXTS['Утяжелитель'].TEXT },
                        'Нет': { coast: 0 }
                    }
                }
            }
        }

        var FiberGlass = (MathCoast) => {
            let countFibers = 'Укажите количество фибергласовых вставок (шт)'
            return {
                'Фибергласовые вставки': {
                    values: {
                        'Да': {
                            childFields: {
                                'Укажите количество фибергласовых вставок (шт)': {
                                    values: {
                                        ...(() => {
                                            let result = {}
                                            for (let i = 0; i < 100; i++) {
                                                result[i + ''] = { coast: 0 }
                                            }
                                            return result
                                        })()
                                    },
                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            let fild = { ...this.state.fields }
                                            let length = this.state.result['Длина (мм)']
                                            if (length != null && length != '' && props.changedField == 'Длина (мм)' ||
                                                (length != null && this.state.nowLoading == 0 && fild['Укажите количество фибергласовых вставок (шт)'].values['1'].coast == 0)
                                            ) {
                                                length = length / 1000

                                                fild[countFibers].MathCoast = (value) => value * length * this.props.TEXTS['Фибергласовые вставки'].TEXT

                                                let values = {}
                                                for (let i = 1; i < 100; i++) {
                                                    values[i + ''] = {
                                                        coast:
                                                            i * length * this.props.TEXTS['Фибергласовые вставки'].TEXT
                                                    }
                                                }
                                                fild[countFibers].values = { ...values }

                                                let res = { ...this.state.result }
                                                if (res[countFibers] != null) {
                                                    res['_' + countFibers] =
                                                        fild[countFibers].values[res[countFibers]].coast
                                                }
                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                                })
                                            } else {
                                                resolve()
                                            }
                                        })
                                    },
                                }
                            }
                        },
                        'Нет': {},
                    }
                }
            }
        }

        var Pokraska = () => {
            return {
                'Покраска (базовая стоимость)': {
                    group: FieldGroups.Additional,
                    values: {
                        'Да': { coast: this.props.TEXTS['Покраска (базовая стоимость)'].TEXT },
                        'Нет': { coast: 0 }
                    }
                }
            }
        }


        this.dataFields = {
            'Раздвижные карнизы': {
                'Бренд': {
                    values: {
                        'DAUERHAFT': {
                            childFields: {
                                'Комплектация': {
                                    values: {
                                        'Бесшумная': { coast: 0 },
                                        'Стандартная': { coast: 0 },
                                    },

                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            let res = { ...{ ...this.state.result } }
                                            let fild = { ...{ ...this.state.fields } }

                                            let needUpdate = false
                                            let needInitShowFunc = false

                                            if (props.changedField == 'Бренд' || props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') {

                                                let width = getWidth(res['Длина (мм)']) * 1

                                                let newCoast = this.props.TEXTS['DAUERHAFT(бесшумные) - дополнительный глайдер'].TEXT * width * 10


                                                if (newCoast != fild['Комплектация'].values['Бесшумная'].coast) {
                                                    fild['Комплектация'].values['Бесшумная'].coast = newCoast

                                                    if (res['Комплектация'] == 'Бесшумная') {
                                                        res['_Комплектация'] = newCoast
                                                    }

                                                    needUpdate = true
                                                }
                                            }

                                            if (res['Комплектация'] == null && this.state.nowLoading == 0) {
                                                res['Комплектация'] = 'Стандартная'
                                                res['_Комплектация'] = 0
                                                needUpdate = true
                                                needInitShowFunc = true
                                            }

                                            if (needUpdate) {
                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, async () => {
                                                        if (needInitShowFunc) {
                                                            await fild['Сторона раздвижки'].onShow({ changedField: 'Комплектация' })
                                                            await fild['Длина (мм)'].onShow({ changedField: 'Комплектация' })
                                                        }
                                                        resolve()
                                                    })
                                                })
                                            } else resolve()


                                        })
                                    }
                                },
                                'Тип управления/мотора': {
                                    values: {
                                        'CM-5 (радио+фазное+сухие контакты)': { coast: this.props.TEXTS['CM-5 (радио+фазное+сухие контакты)'].TEXT },
                                        'CM-3 Wi-Fi (радио+смартфон)': { coast: this.props.TEXTS['CM-3 Wi-Fi (радио+смартфон)'].TEXT },
                                        'CM-2 (наклонный)': { coast: this.props.TEXTS['CM-2 (наклонный)'].TEXT },
                                        'BATTERY - (радио+аккумулятор)': { coast: this.props.TEXTS['BATTERY - (радио+аккумулятор)'].TEXT },
                                    }
                                },



                            }
                        },
                        'NOVO': {
                            childFields: {
                                'Комплектация': {
                                    values: {
                                        'Бесшумная': { coast: 0 },
                                        'Стандартная': { coast: 0 },
                                    },

                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            let res = { ...{ ...this.state.result } }
                                            let fild = { ...{ ...this.state.fields } }

                                            let needUpdate = false
                                            let needInitShowFunc = false

                                            if (props.changedField == 'Бренд' || props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') {

                                                let width = getWidth(res['Длина (мм)']) * 1

                                                let newCoast = this.props.TEXTS['NOVO(бесшумные) - дополнительный глайдер'].TEXT * width * 10


                                                if (newCoast != fild['Комплектация'].values['Бесшумная'].coast) {
                                                    fild['Комплектация'].values['Бесшумная'].coast = newCoast

                                                    if (res['Комплектация'] == 'Бесшумная') {
                                                        res['_Комплектация'] = newCoast
                                                    }

                                                    needUpdate = true
                                                }
                                            }

                                            if (res['Комплектация'] == null && this.state.nowLoading == 0) {
                                                res['Комплектация'] = 'Стандартная'
                                                res['_Комплектация'] = 0
                                                needUpdate = true
                                                needInitShowFunc = true
                                            }

                                            if (needUpdate) {
                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, async () => {
                                                        if (needInitShowFunc) {
                                                            await fild['Сторона раздвижки'].onShow({ changedField: 'Комплектация' })
                                                            await fild['Длина (мм)'].onShow({ changedField: 'Комплектация' })
                                                        }
                                                        resolve()
                                                    })
                                                })
                                            } else resolve()


                                        })
                                    }
                                },
                                'Тип управления/мотора': {
                                    values: {
                                        'N-21 (радио+фазное+сухие контакты)': { coast: this.props.TEXTS['N-21 (радио+фазное+сухие контакты)'].TEXT },
                                        'N-21 Wi-Fi (радио+смартфон)': { coast: this.props.TEXTS['N-21 Wi-Fi (радио+смартфон)'].TEXT },
                                        'N-21 Zigbee (радио+смартфон)': { coast: this.props.TEXTS['N-21 Zigbee (радио+смартфон)'].TEXT },
                                        'N-21 BATTERY (радио+аккумулятор)': { coast: this.props.TEXTS['N-21 BATTERY (радио+аккумулятор)'].TEXT },
                                        'N-18 (радио+фазное+сухие контакты)': { coast: this.props.TEXTS['N-18 (радио+фазное+сухие контакты)'].TEXT },
                                        'N-18 Wi-Fi (радио+смартфон)': { coast: this.props.TEXTS['N-18 Wi-Fi (радио+смартфон)'].TEXT },
                                        'N-17 BATTERY (радио+аккумулятор)': { coast: this.props.TEXTS['N-17 BATTERY (радио+аккумулятор)'].TEXT },
                                    }
                                },
                            }
                        },
                        'SOMFY': {
                            childFields: {
                                'Комплектация': {
                                    values: {
                                        //'Лайт': { coast: 0 }, // убрал комплектацию лайт
                                        'Стандартная': { coast: 0 },
                                        'Бесшумная': { coast: 0 },
                                    },
                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            if (props.changedField == 'Бренд' || props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') {
                                                let fild = { ...{ ...this.state.fields } }
                                                let res = { ...{ ...this.state.result } }

                                                let width = getWidth(res['Длина (мм)']) * 1

                                                let newCoast = this.props.TEXTS['SOMFY(бесшумные) - Глайдер'].TEXT * width * 10


                                                if (newCoast != fild['Комплектация'].values['Бесшумная'].coast) {
                                                    fild['Комплектация'].values['Бесшумная'].coast = newCoast

                                                    if (res['Комплектация'] == 'Бесшумная') {
                                                        res['_Комплектация'] = newCoast
                                                    }

                                                    this.setState({ fields: {}, result: {} }, () => {
                                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                                    })

                                                } else resolve()


                                            } else resolve()
                                        })
                                    }
                                },
                                'Тип управления/мотора': {
                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {

                                            if (props.changedField == 'Комплектация') {
                                                let fild = { ...{ ...this.state.fields } }
                                                let res = { ...{ ...this.state.result } }

                                                /* // убрал комплектацию лайт
                                                if(res['Комплектация']=='Лайт'){
                                                    fild['Тип управления/мотора'].values= {...SOMFY_MOTORS_Light()}
                                                }
                                                */
                                                if (res['Комплектация'] == 'Стандартная' || res['Комплектация'] == 'Бесшумная') {
                                                    fild['Тип управления/мотора'].values = {
                                                        ...SOMFY_MOTORS_Light(),
                                                        ...SOMFY_MOTORS_OTHER()
                                                    }
                                                }
                                                if (res['Тип управления/мотора'] != null) {
                                                    if (fild['Тип управления/мотора'].values[res['Тип управления/мотора']] == null) {

                                                        delete (res['Тип управления/мотора'])
                                                        delete (res['_Тип управления/мотора'])
                                                    } else {
                                                        res['_Тип управления/мотора'] = fild['Тип управления/мотора'].values[res['Тип управления/мотора']].coast
                                                    }
                                                }

                                                if (Object.keys(fild['Тип управления/мотора'].values).length == 0)
                                                    fild['Тип управления/мотора'].disabled = true
                                                else
                                                    fild['Тип управления/мотора'].disabled = false

                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                                })
                                            } else resolve()
                                        })
                                    },
                                    disabled: true,
                                    values: {}
                                },


                            }
                        },
                        'AQARA': {
                            childFields: {
                                'Комплектация': {
                                    values: {
                                        'Бесшумная': { coast: 0 },
                                        'Стандартная': { coast: 0 },
                                    },

                                    onShow: (props) => {
                                        return new Promise((resolve, reject) => {
                                            let res = { ...{ ...this.state.result } }
                                            let fild = { ...{ ...this.state.fields } }

                                            let needUpdate = false
                                            let needInitShowFunc = false

                                            if (props.changedField == 'Бренд' || props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') {

                                                let width = getWidth(res['Длина (мм)']) * 1

                                                let newCoast = this.props.TEXTS['AQARA(бесшумные) - дополнительный глайдер'].TEXT * width * 10


                                                if (newCoast != fild['Комплектация'].values['Бесшумная'].coast) {
                                                    fild['Комплектация'].values['Бесшумная'].coast = newCoast

                                                    if (res['Комплектация'] == 'Бесшумная') {
                                                        res['_Комплектация'] = newCoast
                                                    }

                                                    needUpdate = true
                                                }
                                            }

                                            if (res['Комплектация'] == null && this.state.nowLoading == 0) {
                                                res['Комплектация'] = 'Стандартная'
                                                res['_Комплектация'] = 0
                                                needUpdate = true
                                                needInitShowFunc = true
                                            }

                                            if (needUpdate) {
                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, async () => {
                                                        if (needInitShowFunc) {
                                                            await fild['Сторона раздвижки'].onShow({ changedField: 'Комплектация' })
                                                            await fild['Длина (мм)'].onShow({ changedField: 'Комплектация' })
                                                        }
                                                        resolve()
                                                    })
                                                })
                                            } else resolve()


                                        })
                                    }
                                },

                                'Тип управления/мотора': {
                                    values: {
                                        'ZNCLDJ11LM (приложение)': { coast: this.props.TEXTS['ZNCLDJ11LM (приложение)'].TEXT },
                                        'ZNCLDJ12LM (аккумуляторный)': { coast: this.props.TEXTS['ZNCLDJ12LM (аккумуляторный)'].TEXT },
                                    }
                                },
                            }
                        },
                    }
                },

                'Сторона мотора': {
                    disabled: true,
                    values: {
                        'Слева': { coast: 0 },
                        'Справа': { coast: 0 },
                        'Тандем': { coast: 0 },
                    },
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Бренд' || props.changedField == 'Вид карниза' || props.changedField == 'Тип карниза' || props.changedField == 'Тип управления/мотора') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                if (res['Тип управления/мотора'] == null) {
                                    fild['Сторона мотора'].values['Тандем'].coast = 0
                                    fild['Сторона мотора'].disabled = true
                                } else {
                                    fild['Сторона мотора'].disabled = false
                                    fild['Сторона мотора'].values['Тандем'].coast = this.props.TEXTS[res['Тип управления/мотора'] + ' - Тандем'].TEXT + this.props.TEXTS[res['Тип управления/мотора']].TEXT
                                }
                                if (res['Сторона мотора'] == 'Тандем') {
                                    res['_Сторона мотора'] = fild['Сторона мотора'].values['Тандем'].coast
                                }

                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })

                            } else resolve()
                        })
                    }
                },

                'Сторона раздвижки': {
                    oneCoast: 0,
                    disabled: true,
                    values: {
                        'К мотору': { coast: 0 },
                        'От центра': { coast: 0 },
                        'От мотора': { coast: 0 }
                    },
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Бренд' || props.changedField == 'Комплектация' || props.changedField == 'Сторона мотора' || props.changedField == 'Количество раздвижек') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                let FreeCount = 1

                                let clearData = () => {
                                    fild['Сторона раздвижки'].disabled = true
                                    fild['Количество раздвижек'].disabled = true
                                    fild['Сторона раздвижки'].oneCoast = 0
                                    delete (res['Количество раздвижек'])
                                    delete (res['_Сторона раздвижки'])
                                    delete (res['Сторона раздвижки'])
                                    this.setState({ fields: {}, result: {} }, () => {
                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                    })
                                }

                                if (res['Бренд'] == null && this.state.nowLoading == 0) {
                                    clearData()
                                    return
                                }


                                //////////////////DAUERHAFT
                                if (res['Бренд'] == 'DAUERHAFT')
                                    switch (res['Комплектация']) {


                                        case 'Стандартная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY(лайт)/NOVO/DAUERHAFT/AQARA - раздвижки'].TEXT
                                            FreeCount = 1
                                            break

                                        case 'Бесшумная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['DAUERHAFT - раздвижки Бесшумная'].TEXT
                                            FreeCount = 0
                                            break

                                        default:
                                            clearData()
                                            return
                                    }


                                //////////////////NOVO
                                if (res['Бренд'] == 'NOVO') {
                                    switch (res['Комплектация']) {
                                        case 'Стандартная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY(лайт)/NOVO/DAUERHAFT/AQARA - раздвижки'].TEXT
                                            FreeCount = 1
                                            break

                                        case 'Бесшумная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['NOVO - раздвижки Бесшумная'].TEXT
                                            FreeCount = 0
                                            break

                                        default:
                                            clearData()
                                            return
                                    }
                                }



                                //////////////////SOMFY
                                if (res['Бренд'] == 'SOMFY')
                                    switch (res['Комплектация']) {
                                        case 'Лайт':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY(лайт)/NOVO/DAUERHAFT/AQARA - раздвижки'].TEXT
                                            FreeCount = 1
                                            break

                                        case 'Стандартная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY - раздвижки Стандартная'].TEXT
                                            FreeCount = 1
                                            break

                                        case 'Бесшумная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY - раздвижки Бесшумная'].TEXT
                                            FreeCount = 0
                                            break

                                        default:
                                            clearData()
                                            return
                                    }



                                //////////////////AQARA
                                if (res['Бренд'] == 'AQARA') {
                                    switch (res['Комплектация']) {
                                        case 'Стандартная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['SOMFY(лайт)/NOVO/DAUERHAFT/AQARA - раздвижки'].TEXT
                                            FreeCount = 1
                                            break

                                        case 'Бесшумная':
                                            fild['Сторона раздвижки'].disabled = false
                                            fild['Сторона раздвижки'].oneCoast =
                                                this.props.TEXTS['AQARA - раздвижки Бесшумная'].TEXT
                                            FreeCount = 0
                                            break

                                        default:
                                            clearData()
                                            return
                                    }
                                }






                                let count = res['Количество раздвижек']
                                if (count == 0 || count == null) {
                                    if (this.state.nowLoading == 0)
                                        res['Количество раздвижек'] = 1
                                    count = 1
                                }



                                let isTandem = res['Сторона мотора'] == 'Тандем' ? 0 : 0//у сомфи не сходится ничего

                                fild['Сторона раздвижки'].values['К мотору'].coast = fild['Сторона раздвижки'].oneCoast * (count - FreeCount + isTandem)
                                fild['Сторона раздвижки'].values['От мотора'].coast = fild['Сторона раздвижки'].oneCoast * (count - FreeCount + isTandem)
                                fild['Сторона раздвижки'].values['От центра'].coast = fild['Сторона раздвижки'].oneCoast * (count * 2 - FreeCount + isTandem)


                                if (res['Сторона раздвижки'] != null) {
                                    fild['Количество раздвижек'].disabled = false
                                    res['_Сторона раздвижки'] = fild['Сторона раздвижки'].values[res['Сторона раздвижки']].coast
                                }


                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })

                            } else resolve()
                        })
                    }
                },
                'Количество раздвижек': {
                    disabled: true,
                    group: FieldGroups.Additional,
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            let res = { ...{ ...this.state.result } }
                            let fild = { ...{ ...this.state.fields } }
                            if (props.changedField == 'Сторона раздвижки') {

                                if (res['Сторона раздвижки'] != null) {
                                    fild['Количество раздвижек'].disabled = false
                                    if (res['Количество раздвижек'] == 0 || res['Количество раздвижек'] == null) {
                                        res['Количество раздвижек'] = 1
                                    }
                                } else {
                                    fild['Количество раздвижек'].disabled = true
                                    delete (res['Количество раздвижек'])
                                }

                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })
                            } else resolve()
                        })
                    },
                    values: {
                        1: { coast: 0 },
                        2: { coast: 0 },
                        3: { coast: 0 },
                    }
                },

                'Длина (мм)': {
                    disabled: true,
                    max: 100000,
                    type: 'number',
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Комплектация' || props.changedField == 'Бренд' || props.changedField == 'Тип управления/мотора') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                const MotorsTypesStarts = {
                                    'Move': [20725, 22725, 'Move'],
                                    'Glydea': [38224, 40724, 'Glydea'],
                                    'Irismo': [38224, 40724, 'Irismo'],
                                }

                                let selectedMotorType = [0, 0]
                                if (res['Тип управления/мотора'] != null) {
                                    Object.keys(MotorsTypesStarts).forEach(motor => {
                                        if (res['Тип управления/мотора'].indexOf(motor) > -1) {
                                            selectedMotorType = MotorsTypesStarts[motor]
                                        }
                                    })
                                }

                                if (res['Бренд'] != null)
                                    fild['Длина (мм)'].disabled = false
                                else
                                    fild['Длина (мм)'].disabled = true

                                if (res['Бренд'] == 'DAUERHAFT') {

                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 10890, min: 0.5, max: 12,
                                            func: (prev, step, i) => prev + 164
                                        })
                                    )
                                    fild['Длина (мм)'].max = 12 * 1000
                                }


                                if (res['Бренд'] == 'NOVO') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 7865, min: 0.5, max: 12,
                                            func: (prev, step, i) => prev + 165
                                        })
                                    )
                                    fild['Длина (мм)'].max = 12 * 1000
                                }

                                if (res['Бренд'] == 'NOVOmini') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 8500, min: 0.5, max: 2.5,
                                            func: (prev, step, i) => prev + 250
                                        })
                                    )
                                    fild['Длина (мм)'].max = 2.5 * 1000
                                }


                                if (res['Бренд'] == 'SOMFY') {
                                    if (res['Комплектация'] == null) {
                                        res['_Длина (мм)'] = 0
                                        fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                            this.funcValues({
                                                start: 0, min: 0.5, max: 12,
                                                func: (prev, step, i) => 0
                                            })
                                        )
                                        fild['Длина (мм)'].max = 12 * 1000
                                        fild['Длина (мм)'].disabled = true
                                    } else {
                                        if (res['Комплектация'] == 'Лайт')
                                            fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                                this.funcValues({
                                                    start: selectedMotorType[0], min: 0.5, max: 12,
                                                    func: (prev, step, i) => prev + 156
                                                })
                                            )
                                        if (res['Комплектация'] == 'Стандартная' || res['Комплектация'] == 'Бесшумная')
                                            fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                                this.funcValues({
                                                    start: selectedMotorType[1], min: 0.5, max: 12,
                                                    func: (prev, step, i) =>
                                                        selectedMotorType[2] == 'Move' ? prev + 156 :
                                                            i > 3 ? prev + 250 : prev + 156
                                                })
                                            )
                                        fild['Длина (мм)'].max = 12 * 1000
                                    }
                                }

                                if (res['Бренд'] == 'AQARA') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 14625, min: 0.5, max: 12,
                                            func: (prev, step, i) => prev + 150
                                        })
                                    )
                                    fild['Длина (мм)'].max = 12 * 1000
                                }

                                if (res['Длина (мм)'] * 1 > fild['Длина (мм)'].max * 1)
                                    res['Длина (мм)'] = fild['Длина (мм)'].max * 1


                                debugger

                                if (res['Длина (мм)'] != null)
                                    res['_Длина (мм)'] = fild['Длина (мм)'].MathCoast(res['Длина (мм)'])

                                debugger

                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })
                            } else resolve()
                        })
                    },
                    MathCoast: value => 0,
                },

                "Нужен стык": {
                    disabled: true,
                    values: {
                        'Да': { coast: 0 },
                        'Нет': { coast: 0 }
                    },
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Длина (мм)') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }
                                if (Number(res['Длина (мм)']) >= 3000) {
                                    fild['Нужен стык'].disabled = false
                                } else {
                                    fild['Нужен стык'].disabled = true
                                    res['Нужен стык'] = 'Нет'
                                }

                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })
                            } else resolve()
                        })
                    }
                },


                ...this.GnutieDugovoe(),
                'Угловой элемент (тип)': {
                    group: FieldGroups.Additional,
                    disabled: true,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            let fild = { ...this.state.fields }
                            let res = { ...this.state.result }

                            if (props.changedField == 'Тип гибки') {
                                if (res['Тип гибки'] == 'Загиб угла') {
                                    if (fild['Угловой элемент (тип)'].disabled) {
                                        fild['Угловой элемент (тип)'].disabled = false
                                        fild['Угловой элемент (шт)'].disabled = false

                                        this.setState({ fields: { ...fild } }, () => resolve())
                                    }
                                } else if (fild['Угловой элемент (тип)'].disabled != true) {

                                    fild['Угловой элемент (тип)'].disabled = true
                                    fild['Угловой элемент (шт)'].disabled = true
                                    delete (res['Угловой элемент (тип)'])
                                    delete (res['Угловой элемент (шт)'])
                                    delete (res['_Угловой элемент (шт)'])
                                    this.setState({ fields: {}, result: {} }, () => this.setState({ fields: { ...fild }, result: { ...res } }, this.setState({ fields: {}, result: {} }, () => {
                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                    })))

                                } else resolve()
                            } else resolve()
                        })

                    },
                    values: {
                        'Нет': {},
                        'Угол 90': {},
                        'Угол 135': {}
                    }
                },
                'Угловой элемент (шт)': {
                    disabled: true,
                    group: FieldGroups.Additional,
                    values: {
                        '0': { coast: 0 },
                        '1': { coast: this.props.TEXTS['Угловой элемент'].TEXT },
                        '2': { coast: this.props.TEXTS['Угловой элемент'].TEXT * 2 },
                    }
                },
                'Дополнительный глайдер': {
                    group: FieldGroups.Additional,
                    type: 'number',
                    oneCoast: 0,
                    disabled: true,
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            let fild = { ...{ ...this.state.fields } }
                            let res = { ...{ ...this.state.result } }

                            if (props.changedField == 'Бренд' || props.changedField == 'Комплектация') {


                                if (res['Бренд'] == null) {

                                    fild['Дополнительный глайдер'].disabled = true
                                    fild['Дополнительный глайдер'].oneCoast = 0
                                    fild['Дополнительный глайдер'].MathCoast = v => 0
                                    delete (res['Дополнительный глайдер'])
                                    delete (res['_Дополнительный глайдер'])
                                    this.setState({ fields: {}, result: {} }, () => {
                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                    })
                                } else {

                                    let newOneCoast = fild['Дополнительный глайдер'].oneCoast * 1
                                    if (res['Бренд'] == 'SOMFY' && res['Комплектация'] == 'Лайт' || res['Бренд'] != 'SOMFY') {
                                        if (res['Бренд'] == 'DAUERHAFT' && res['Комплектация'] == 'Бесшумная')
                                            newOneCoast = this.props.TEXTS['DAUERHAFT(бесшумные) - дополнительный глайдер'].TEXT
                                        else
                                            newOneCoast = this.props.TEXTS['SOMFY(лайт)/NOVO/DAUERHAFT/AQARA - Глайдер'].TEXT
                                    } else
                                        if (res['Бренд'] == 'SOMFY' && res['Комплектация'] == 'Стандартная') {
                                            newOneCoast = this.props.TEXTS['SOMFY(стандарт) - Глайдер'].TEXT
                                        } else
                                            if (res['Бренд'] == 'SOMFY' && res['Комплектация'] == 'Бесшумная') {
                                                newOneCoast = this.props.TEXTS['SOMFY(бесшумные) - Глайдер'].TEXT
                                            }

                                    if (newOneCoast != fild['Дополнительный глайдер'].oneCoast) {

                                        fild['Дополнительный глайдер'].disabled = false
                                        fild['Дополнительный глайдер'].oneCoast = newOneCoast
                                        fild['Дополнительный глайдер'].MathCoast = (value) => value * newOneCoast
                                        if (res['Дополнительный глайдер'] != null)
                                            res['_Дополнительный глайдер'] = res['Дополнительный глайдер'] * newOneCoast

                                        this.setState({ fields: {}, result: {} }, () => {
                                            this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                        })
                                    } else {
                                        resolve()
                                    }
                                }


                            } else {
                                if (props.changedField == 'Тип карниза' && res['Тип карниза'] == 'Раздвижные карнизы') {//тоесть мы поменяль на Раздвижные карнизы
                                    fild['Дополнительный глайдер'].disabled = true
                                    res['_Дополнительный глайдер'] = 0
                                    fild['Дополнительный глайдер'].oneCoast = 0
                                    fild['Дополнительный глайдер'].MathCoast = v => 0

                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                } else resolve()
                            }
                        })
                    },
                    MathCoast: value => 0,
                },
                ...Kreplenie()
            },

            'Римские шторы': {
                'Бренд': {
                    values: {
                        'DAUERHAFT': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Roll 35-3 (радио)': { coast: this.props.TEXTS['Roll 35-3 (радио)'].TEXT },
                                        'Roll 35-IB (радио+сухие)': { coast: this.props.TEXTS['Roll 35-IB (радио+сухие)'].TEXT },
                                        'Roll 35-4 (фазный)': { coast: this.props.TEXTS['Roll 35-4 (фазный)'].TEXT },
                                        'Roll 35-BATTERY (аккумуляторный)': { coast: this.props.TEXTS['Roll 35-BATTERY (аккумуляторный)'].TEXT },
                                        'Roll 35-Wi-Fi (радио+Wi-Fi)': { coast: this.props.TEXTS['Roll 35-Wi-Fi (радио+Wi-Fi)'].TEXT },
                                    }
                                },
                            }
                        },
                        'NOVO': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Novo 35-3 (радио+сухие)': { coast: this.props.TEXTS['Novo 35-3 (радио+сухие)'].TEXT },
                                        'Novo 35-4 (фазный)': { coast: this.props.TEXTS['Novo 35-4 (фазный)'].TEXT },
                                    }
                                },
                            }
                        },
                        'NOVOmini': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Мотор 24 мм (радио 24V)': { coast: this.props.TEXTS['Мотор 24 мм (радио 24V)'].TEXT },
                                        'Мотор 24 мм (радио 12V АКБ)': { coast: this.props.TEXTS['Мотор 24 мм (радио 12V АКБ)'].TEXT },
                                    }
                                },
                            }
                        },
                        'SOMFY': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Sonesse 40 RTS (радио+сухие)': { coast: this.props.TEXTS['Sonesse 40 RTS (радио+сухие)'].TEXT },
                                        'Sonesse 40 WT (фазное)': { coast: this.props.TEXTS['Sonesse 40 WT (фазное)'].TEXT },
                                        'Sonesse 30 DCT 2/28 (сухие)': { coast: this.props.TEXTS['Sonesse 30 DCT 2/28 (сухие)'].TEXT },
                                        'Sonesse 30 ULTRA WF RTS (аккумуляторный)': {
                                            coast: this.props.TEXTS['Sonesse 30 ULTRA WF RTS (аккумуляторный)'].TEXT,
                                            childFields: {
                                                'Зарядное устройство': {
                                                    values: {
                                                        'Да': { coast: this.props.TEXTS['Somfy зарядное устройство:  рулонные/римские шторы (аккум. WF)'].TEXT },
                                                        'Нет': { coast: 0 },
                                                    }
                                                },
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        'AQARA': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Мотор 35 мм (приложение + пульт Zigbee)': { coast: this.props.TEXTS['Мотор 35 мм (приложение + пульт Zigbee)'].TEXT },
                                    }
                                },
                            }
                        }
                    },
                },
                'Длина (мм)': {
                    disabled: true,
                    max: 100000,
                    min: 100,
                    type: 'number',
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Бренд' || props.changedField == 'Тип управления/мотора' || props.changedField == 'Длина (мм)' || props.changedField == 'Высота (мм)') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                if (res['Бренд'] != null)
                                    fild['Длина (мм)'].disabled = false
                                else
                                    fild['Длина (мм)'].disabled = true

                                if (res['Бренд'] == 'DAUERHAFT') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 11808, min: 0.6, max: 6,
                                            func: (prev, step, i) => prev + 263
                                        })
                                    )


                                    if (res['Тип управления/мотора'] == 'Roll 35-BATTERY (аккумуляторный)') {
                                        fild['Длина (мм)'].max = 6 * 1000
                                        fild['Длина (мм)'].min = 0.840 * 1000

                                        fild['Высота (мм)'].max = 6.5 * 1000
                                        fild['Высота (мм)'].min = 0.840 * 1000
                                    } else {
                                        fild['Длина (мм)'].max = 6 * 1000
                                        fild['Длина (мм)'].min = 0.605 * 1000

                                        fild['Высота (мм)'].max = 6.5 * 1000
                                        fild['Высота (мм)'].min = 0.605 * 1000
                                    }


                                }

                                if (res['Бренд'] == 'NOVO') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 9568, min: 0.6, max: 6,
                                            func: (prev, step, i) => prev + 263
                                        })
                                    )
                                    fild['Длина (мм)'].max = 6 * 1000
                                    fild['Длина (мм)'].min = 0.615 * 1000

                                    fild['Высота (мм)'].max = 6.5 * 1000
                                    fild['Высота (мм)'].min = 0.615 * 1000
                                }

                                if (res['Бренд'] == 'NOVOmini') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 8500, min: 0.6, max: 3,
                                            func: (prev, step, i) => prev + 250
                                        })
                                    )
                                    fild['Длина (мм)'].max = 3 * 1000
                                    fild['Длина (мм)'].min = 0.515 * 1000

                                    fild['Высота (мм)'].max = 4 * 1000
                                    fild['Высота (мм)'].min = 0.515 * 1000
                                }

                                if (res['Бренд'] == 'SOMFY') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 30559, min: 0.65, max: 6,
                                            func: (prev, step, i) => prev + 163
                                        })
                                    )
                                    fild['Длина (мм)'].max = 6 * 1000
                                    fild['Длина (мм)'].min = 0.65 * 1000

                                    fild['Высота (мм)'].max = 6.5 * 1000
                                    fild['Высота (мм)'].min = 0.65 * 1000
                                }

                                if (res['Бренд'] == 'AQARA') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 19362.5, min: 0.7, max: 6,
                                            func: (prev, step, i) => prev + 187.5
                                        })
                                    )

                                    fild['Длина (мм)'].max = 6 * 1000
                                    fild['Длина (мм)'].min = 0.7 * 1000

                                    fild['Высота (мм)'].max = 6.5 * 1000
                                    fild['Высота (мм)'].min = 0.7 * 1000
                                }




                                if (res['Длина (мм)'] != null)
                                    res['_Длина (мм)'] = fild['Длина (мм)'].MathCoast(res['Длина (мм)'])

                                if (res['Высота (мм)'] != null)
                                    res['_Высота (мм)'] = fild['Высота (мм)'].MathCoast(res['Высота (мм)'])



                                let errorFields = [...[...this.state.errorFields]];
                                const errorFieldsDescr = { ...this.state.errorFieldsDescr };

                                (['Длина (мм)', 'Высота (мм)']).forEach(key => {

                                    if (
                                        res[key] * 1 < fild[key].min ||
                                        res[key] * 1 > fild[key].max
                                    ) {

                                        errorFields.push(key)
                                        errorFieldsDescr[key] = `Невозможно рассчитать такой размер для этого бренда`


                                    } else {

                                        errorFields = errorFields.filter(f => f != key)
                                        errorFieldsDescr[key] = null
                                    }
                                })



                                this.setState({ fields: {}, result: {}, errorFields: [], errorFieldsDescr: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res }, errorFields, errorFieldsDescr }, () => resolve())
                                })
                            } else resolve()
                        })
                    },
                    MathCoast: value => 0,
                },
                'Высота (мм)': {
                    type: 'number',
                    MathCoast: (value) => getWidthCoast(value,
                        this.funcValues({
                            start: 0, min: 0.4, max: 7,
                            func: (prev, step, i) => 0
                        })
                    )
                },
                ...Ytajelitel(),
                'Сторона мотора': {
                    values: {
                        'Слева': {},
                        'Справа': {}
                    }
                },
                ...Kreplenie(),
                ...FiberGlass(),
                'Количество кордов (шт)': {
                    type: "number",
                    MathCoast: v => 0,
                    disabled: true,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Длина (мм)' || props.changedField == 'Бренд') {
                                let res = { ...{ ...this.state.result } }
                                if (res['Длина (мм)'] == null || res['Длина (мм)'] == '' || res['Бренд'] == null || res['Бренд'] == '') {
                                    res['Количество кордов (шт)'] = 0
                                    res['Расст. между кордами (см)'] = 0
                                } else {

                                    res['Количество кордов (шт)'] = KordsCount[
                                        getWidth(
                                            res['Длина (мм)']
                                        )
                                    ][res['Бренд']]

                                    res['Расст. между кордами (см)'] = ((getWidth(res['Длина (мм)']) - 0.14) / (res['Количество кордов (шт)'] * 1 - 1)).toFixed(3)
                                }
                                this.setState({ result: { ...res } }, () => resolve())
                            } else resolve()
                        })
                    }
                },
                'Расст. между кордами (см)': {
                    type: "number",
                    MathCoast: v => 0,
                    disabled: true,
                }
            },

            'Рулонные шторы': {
                'Бренд': {

                    values: {
                        'DAUERHAFT': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Roll 25-3 (радио)': { coast: this.props.TEXTS['Roll 25-3 (радио)'].TEXT },
                                        'Roll 25-4 (фазный)': { coast: this.props.TEXTS['Roll 25-4 (фазный)'].TEXT },
                                        'Roll 25 BATTERY (аккумуляторный)': {
                                            coast: this.props.TEXTS['Roll 25 BATTERY (аккумуляторный)'].TEXT,
                                            childFields: {
                                                'Зарядное устройство': {
                                                    values: {
                                                        'Да': { coast: this.props.TEXTS['Somfy зарядное устройство:  рулонные/римские шторы (аккум. WF)'].TEXT },
                                                        'Нет': { coast: 0 },
                                                    }
                                                },
                                            }
                                        },
                                        'Roll 35-3 (радио)': { coast: this.props.TEXTS['Roll 35-3 (радио)'].TEXT },
                                        'Roll 35-IB (радио+сухие)': { coast: this.props.TEXTS['Roll 35-IB (радио+сухие)'].TEXT },
                                        'Roll 35-4 (фазный)': { coast: this.props.TEXTS['Roll 35-4 (фазный)'].TEXT },
                                        'Roll 35-BATTERY (аккумуляторный)': {
                                            coast: this.props.TEXTS['Roll 35 BATTERY (аккумуляторный)'].TEXT,
                                            childFields: {
                                                'Зарядное устройство': {
                                                    values: {
                                                        'Да': { coast: this.props.TEXTS['Somfy зарядное устройство:  рулонные/римские шторы (аккум. WF)'].TEXT },
                                                        'Нет': { coast: 0 },
                                                    }
                                                },
                                            }
                                        },
                                        'Roll 35-Wi-Fi (радио+Wi-Fi)': { coast: this.props.TEXTS['Roll 35-Wi-Fi (радио+Wi-Fi)'].TEXT },
                                    }
                                },
                            }
                        },
                        'NOVO': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Novo 25-3 (радио)': { coast: this.props.TEXTS['Novo 25-3 (радио)'].TEXT },
                                        'Novo 25мм (радио+сухие)': { coast: this.props.TEXTS['Мотор 25мм (радио+сухие)'].TEXT },
                                        'Novo 25 BATTERY (аккумуляторный)': { coast: this.props.TEXTS['Novo 25 BATTERY (аккумуляторный)'].TEXT },
                                        'Novo 35-3 (радио)': { coast: this.props.TEXTS['Novo 35-3 (радио)'].TEXT },
                                        'Novo 35-IB (радио+сухие)': { coast: this.props.TEXTS['Novo 35-IB (радио+сухие)'].TEXT },
                                        'Novo 35-4 (фазный)': { coast: this.props.TEXTS['Novo 35-4 (фазный)'].TEXT },
                                    }
                                },
                            }
                        },
                        'SOMFY': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Sonesse 40 RTS (радио+сухие)': { coast: this.props.TEXTS['Sonesse 40 RTS (радио+сухие)'].TEXT },
                                        'Sonesse 40 WT (фазное)': { coast: this.props.TEXTS['Sonesse 40 WT (фазное)'].TEXT },
                                        'Sonesse 30 DCT 2/28 (сухие)': { coast: this.props.TEXTS['Sonesse 30 DCT 2/28 (сухие)'].TEXT },
                                        'Sonesse 30 ULTRA WF RTS (аккумуляторный)': {
                                            coast: this.props.TEXTS['Sonesse 30 ULTRA WF RTS (аккумуляторный)'].TEXT,
                                            childFields: {
                                                'Зарядное устройство': {
                                                    values: {
                                                        'Да': { coast: this.props.TEXTS['Somfy зарядное устройство:  рулонные/римские шторы (аккум. WF)'].TEXT },
                                                        'Нет': { coast: 0 },
                                                    }
                                                },
                                            }
                                        },
                                    }
                                },
                            }
                        },
                        'AQARA': {
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'Мотор 35 мм (приложение + пульт Zigbee)': { coast: this.props.TEXTS['Мотор 35 мм (приложение + пульт Zigbee)'].TEXT },
                                    }
                                },
                            }
                        }
                    },
                },
                'Длина (мм)': {
                    disabled: true,
                    type: 'number',
                    min: 100,
                    max: 10000,
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Бренд' || props.changedField == 'Тип управления/мотора' || props.changedField == 'Длина (мм)' || props.changedField == 'Высота (мм)') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                if (res['Бренд'] != null)
                                    fild['Длина (мм)'].disabled = false
                                else
                                    fild['Длина (мм)'].disabled = true

                                if (res['Бренд'] == 'DAUERHAFT') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 10670, min: 0.5, max: 4,
                                            func: (prev, step, i) => prev + 113
                                        })
                                    )

                                    if (res['Тип управления/мотора'].indexOf('25')) {
                                        if (res['Тип управления/мотора'].indexOf('радио') != -1 ||
                                            res['Тип управления/мотора'].indexOf('аккумуляторный') != -1) {

                                            fild['Длина (мм)'].min = 0.520 * 1000
                                            fild['Высота (мм)'].min = 0.520 * 1000
                                        } else {
                                            fild['Длина (мм)'].min = 0.545 * 1000
                                            fild['Высота (мм)'].min = 0.545 * 1000
                                        }
                                    } else {
                                        if (res['Тип управления/мотора'].indexOf('аккумуляторный') != -1) {
                                            fild['Длина (мм)'].min = 0.820 * 1000
                                            fild['Высота (мм)'].min = 0.820 * 1000
                                        } else {
                                            fild['Длина (мм)'].min = 0.570 * 1000
                                            fild['Высота (мм)'].min = 0.570 * 1000
                                        }
                                    }


                                    fild['Длина (мм)'].max = 2.7 * 1000
                                    fild['Высота (мм)'].max = 3.4 * 1000

                                }

                                if (res['Бренд'] == 'NOVO') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 8070, min: 0.5, max: 5,
                                            func: (prev, step, i) => prev + 113
                                        })
                                    )

                                    if (res['Тип управления/мотора'].indexOf('25')) {
                                        fild['Длина (мм)'].min = 0.470 * 1000
                                        fild['Высота (мм)'].min = 0.470 * 1000
                                    } else {
                                        fild['Длина (мм)'].min = 0.585 * 1000
                                        fild['Высота (мм)'].min = 0.585 * 1000
                                    }

                                    fild['Длина (мм)'].max = 2.7 * 1000
                                    fild['Высота (мм)'].max = 3.4 * 1000
                                }


                                if (res['Бренд'] == 'SOMFY') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 28000.5, min: 0.5, max: 3,
                                            func: (prev, step, i) => prev + 112.5
                                        })
                                    )
                                    fild['Длина (мм)'].min = 0.610 * 1000
                                    fild['Высота (мм)'].min = 0.610 * 1000

                                    fild['Длина (мм)'].max = 2.7 * 1000
                                    fild['Высота (мм)'].max = 3.4 * 1000
                                }

                                if (res['Бренд'] == 'AQARA') {
                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 18313.5, min: 0.5, max: 3,
                                            func: (prev, step, i) => prev + 62.5
                                        })
                                    )
                                    fild['Длина (мм)'].min = 0.670 * 1000
                                    fild['Высота (мм)'].min = 0.670 * 1000

                                    fild['Длина (мм)'].max = 2.7 * 1000
                                    fild['Высота (мм)'].max = 3.4 * 1000
                                }

                                if (res['Длина (мм)'] != null)
                                    res['_Длина (мм)'] = fild['Длина (мм)'].MathCoast(res['Длина (мм)'])

                                if (res['Высота (мм)'] != null)
                                    res['_Высота (мм)'] = fild['Высота (мм)'].MathCoast(res['Высота (мм)'])



                                let errorFields = [...[...this.state.errorFields]];
                                const errorFieldsDescr = { ...this.state.errorFieldsDescr };

                                (['Длина (мм)', 'Высота (мм)']).forEach(key => {

                                    if (
                                        res[key] * 1 < fild[key].min ||
                                        res[key] * 1 > fild[key].max
                                    ) {

                                        errorFields.push(key)
                                        errorFieldsDescr[key] = `Невозможно рассчитать такой размер для этого бренда`


                                    } else {

                                        errorFields = errorFields.filter(f => f != key)
                                        errorFieldsDescr[key] = null
                                    }
                                })



                                this.setState({ fields: {}, result: {}, errorFields: [], errorFieldsDescr: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res }, errorFields, errorFieldsDescr }, () => resolve())
                                })
                            } else resolve()
                        })
                    },
                    MathCoast: value => 0,
                },
                'Высота (мм)': {
                    type: 'number',
                    max: 7 * 1000,
                    MathCoast: (value) => getWidthCoast(value,
                        this.funcValues({
                            start: 0, min: 0.1, max: 7,
                            func: (prev, step, i) => 0
                        })
                    )
                },
                'Сторона мотора': {
                    values: {
                        'Слева': {},
                        'Справа': {}
                    }
                },
                'Вид намотки': {
                    values: {
                        'Рулон виден': {},
                        'Рулон не виден': {}
                    }
                },

                /*
               'Монтажная планка':{
                   onShow:(props)=>{
                       return new Promise((resolve,reject)=>{
                           if(props.changedField=='Бренд' || props.changedField=='Длина (мм)'){
                               let fild = {...{...this.state.fields}}
                               let res = {...{...this.state.result}}
                               let length = res['Длина (мм)']==null? 0 :res['Длина (мм)']*1
       
                               if(res['Бренд']=='SOMFY' && this.state.fields['Монтажная планка'].values['Да'].coast!=length * this.props.TEXTS['Монтажная планка (для рулонных штор) Somfy'].TEXT){
                                   fild['Монтажная планка'].values['Да'].coast = length * this.props.TEXTS['Монтажная планка (для рулонных штор) Somfy'].TEXT
                               }else if(res['Бренд']!='SOMFY' && this.state.fields['Монтажная планка'].values['Да'].coast!=length * this.props.TEXTS['Монтажная планка (для рулонных штор)'].TEXT){
                                   fild['Монтажная планка'].values['Да'].coast = length * this.props.TEXTS['Монтажная планка (для рулонных штор)'].TEXT
                               }
                               
                               if(res['Монтажная планка']=='Да'){
                                   res['_Монтажная планка'] =  fild['Монтажная планка'].values['Да'].coast
                               }
                               this.setState({fields:{...fild},result:{...res}},()=>resolve())
                           }else resolve()
                       })
                   },
                   values:{
                       'Нет':{coast:0},
                       'Да':{coast:0}
                   }
               },
               */
                /*
                'Утяжелитель':{
                    onShow:(props)=>{
                        return new Promise((resolve,reject)=>{
                            if(props.changedField=='Длина (мм)'){
                                let fild = {...{...this.state.fields}}
                                let res = {...{...this.state.result}}
        
                                let length = res['Длина (мм)']*1
                                fild['Утяжелитель'].values['Обернут тканью'].coast =  length * this.props.TEXTS['Утяжелитель обернут тканью'].TEXT
                                if(res['Утяжелитель']=='Обернут тканью')
                                    res['_Утяжелитель']= fild['Утяжелитель'].values['Обернут тканью'].coast
                                
                                
                                this.setState({fields:{...fild},result:{...res}},()=>resolve())
                            }else resolve()
                        })
                    },
                    values:{
                        'Стандартный':{coast:0},
                        'Обернут тканью':{coast:0}
                    }
                },
                */

                'Багет': {
                    group: FieldGroups.Additional,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Длина (мм)') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                let length = getWidth(res['Длина (мм)'])
                                fild['Багет'].values['Да'].coast = length * this.props.TEXTS['Багет'].TEXT
                                if (res['Багет'] == 'Да')
                                    res['_Багет'] = fild['Багет'].values['Да'].coast


                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                            } else resolve()
                        })
                    },
                    values: {
                        'Нет': { coast: 0 },
                        'Да': { coast: 0 }
                    }
                },

                'Боковые направляющие': {
                    group: FieldGroups.Additional,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Высота (мм)') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                let length = getWidth(res['Высота (мм)'])
                                fild['Боковые направляющие'].values['Да'].coast = length * this.props.TEXTS['Боковые направляющие'].TEXT
                                if (res['Боковые направляющие'] == 'Да')
                                    res['_Боковые направляющие'] = fild['Боковые направляющие'].values['Да'].coast


                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                            } else resolve()
                        })
                    },
                    values: {
                        'Нет': { coast: 0 },
                        'Да': { coast: 0 }
                    }
                },

                'Категория ткани': {
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {

                            if (props.changedField == 'Высота (мм)' || props.changedField == 'Длина (мм)' || props.changedField == 'Тип карниза') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }
                                let _height = res['Высота (мм)']
                                let _width = res['Длина (мм)']


                                if (_height == null || _width == null || res['Тип карниза'] != 'Рулонные шторы') {
                                    fild['Категория ткани'].values = {}
                                    this.setState({ fields: {}, result: {} }, () => {
                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                    })
                                    return
                                }

                                _height = getWidth(_height)
                                _width = getWidth(_width)




                                let kat = ['E категория', '1 категория', '2 категория', '3 категория', '4 категория', '5 категория']
                                let newValues = {}

                                const Tkani = this.props.TEXTS['Цены на ткани'].TEXT


                                kat.forEach(k => {
                                    let height = _height + ''
                                    let width = _width + ''

                                    let tkan = Tkani[k]
                                    let keys = Object.keys(tkan)

                                    if (keys[0] * 1 >= height * 1) //маленькое значение
                                        height = keys[0]
                                    else if (tkan[(height * 1 + 0.1).toFixed(1)] != null) //Соседние значения
                                        height = (height * 1 + 0.1).toFixed(1) + ''
                                    else if (keys[keys.length - 1] * 1 <= height * 1) //большое значение
                                        height = keys[keys.length - 1]

                                    if (k == '1 категория') { }

                                    if (tkan[height] != null) {

                                        keys = Object.keys(tkan[height])
                                        if (keys[0] * 1 >= width * 1) //маленькое значение
                                            width = keys[0] * 1
                                        else if (keys[keys.length - 1] * 1 <= width * 1) //маленькое значение
                                            width = keys[keys.length - 1]
                                    }
                                    if (k == '1 категория') { }

                                    if (!(tkan[height] == null || tkan[height][width] == null || tkan[height][width] == '')) {
                                        newValues[k] = { coast: (tkan[height][width] * 1 * (this.props.TEXTS['Курс $'].TEXT)).toFixed(2) * 1 }
                                    }
                                    if (k == '1 категория') { }
                                })



                                if (res['Категория ткани'] != null) {
                                    if (Object.keys(newValues).findIndex(key => key == res['Категория ткани']) == -1) {
                                        //если выбранной ткани больше нет в списке
                                        alert('Выбранной такни нет на данный размер изделия')
                                        delete (res['Категория ткани'])
                                        delete (res['Цена ткани'])
                                        delete (res['_Категория ткани'])
                                    } else {
                                        res['_Категория ткани'] = newValues[res['Категория ткани']].coast
                                        res['Цена ткани'] = newValues[res['Категория ткани']].coast
                                    }
                                }

                                fild['Категория ткани'].values = { ...newValues }

                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })

                            } else resolve()
                        })
                    },
                    values: {
                        //значения динамические совсем
                    }
                },
                'Цена ткани': {
                    type: 'number',
                    disabled: true,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Категория ткани' || props.changedField == 'Длина (мм)' || props.changedField == 'Высота (мм)') {
                                let res = { ...{ ...this.state.result } }
                                let fild = { ...{ ...this.state.fields } }
                                res['Цена ткани'] = res['_Категория ткани']
                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })
                            } else resolve()
                        })
                    }
                }
            },

            'Зимний сад': {
                'Бренд': {
                    values: {
                        'DAUERHAFT': {
                            coast: 4000,
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'CM-2': { coast: this.props.TEXTS['Зимний сад - CM-2'].TEXT * 3.59375 },
                                    }
                                },
                            }
                        },
                        'NOVO': {
                            coast: 0,
                            childFields: {
                                'Тип управления/мотора': {
                                    values: {
                                        'N-19': { coast: this.props.TEXTS['Зимний сад - N-19'].TEXT * 3.59375 },
                                    }
                                },

                            }
                        },
                    },
                },
                'Длина (мм)': {
                    disabled: true,
                    type: 'number',
                    max: 6000,
                    MathCoast: value => 0
                },
                'Ширина (мм)': {
                    disabled: true,
                    type: 'number',
                    max: 1000000,
                    onShow: props => {
                        return new Promise((resolve, reject) => {
                            if (props.changedField == 'Бренд') {
                                let fild = { ...{ ...this.state.fields } }
                                let res = { ...{ ...this.state.result } }

                                if (res['Бренд'] != null) {
                                    fild['Ширина (мм)'].disabled = false
                                    fild['Длина (мм)'].disabled = false
                                } else {
                                    fild['Ширина (мм)'].disabled = true
                                    fild['Длина (мм)'].disabled = true
                                }

                                if (res['Бренд'] == 'DAUERHAFT') {
                                    fild['Ширина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 1 * 800 * 3.59375, min: 1, max: 5,
                                            func: (prev, step, i) => i * 800 * 3.59375
                                        })

                                    )
                                    fild['Ширина (мм)'].max = 5 * 1000


                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 1 * 800 * 3.59375, min: 1, max: 6,
                                            func: (prev, step, i) => i * 800 * 3.59375
                                        })
                                    )
                                    fild['Длина (мм)'].max = 6 * 1000

                                }

                                if (res['Бренд'] == 'NOVO') {
                                    fild['Ширина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 0.6 * 800 * 3.59375, min: 0.6, max: 2.5,
                                            func: (prev, step, i) => i * 800 * 3.59375
                                        })
                                    )
                                    fild['Ширина (мм)'].max = 2.5 * 1000

                                    fild['Длина (мм)'].MathCoast = (value) => getWidthCoast(value,
                                        this.funcValues({
                                            start: 1 * 800 * 3.59375, min: 1, max: 6,
                                            func: (prev, step, i) => i * 800 * 3.59375
                                        })
                                    )
                                    fild['Длина (мм)'].max = 6 * 1000

                                }


                                if (res['Длина (мм)'] * 1 > fild['Длина (мм)'].max * 1)
                                    res['Длина (мм)'] = fild['Длина (мм)'].max * 1


                                if (res['Ширина (мм)'] * 1 > fild['Ширина (мм)'].max * 1)
                                    res['Ширина (мм)'] = fild['Ширина (мм)'].max * 1

                                if (res['Ширина (мм)'] != null)
                                    res['_Ширина (мм)'] = fild['Ширина (мм)'].MathCoast(res['Ширина (мм)'])

                                if (res['Длина (мм)'] != null)
                                    res['_Длина (мм)'] = fild['Длина (мм)'].MathCoast(res['Высота (мм)'])



                                this.setState({ fields: {}, result: {} }, () => {
                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                })
                            } else resolve()
                        })
                    },
                    MathCoast: value => 0
                },
                'Шаг складки': {
                    type: 'number',
                    disabled: true,
                    MathCoast: v => 0,
                },
                ...this.GnutieDugovoe(),
                'Количество направляющих': {
                    type: 'number',
                    disabled: true,
                    MathCoast: v => 0,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            let res = { ...{ ...this.state.result } }
                            let fild = { ...{ ...this.state.fields } }
                            let length = getWidth(res['Длина (мм)'])
                            if (props.changedField != 'Длина (мм)' || length == null) {
                                if (length == null && res['Количество направляющих'] != null) {
                                    delete (res['Количество направляющих'])
                                    delete (res['Шаг складки'])
                                    this.setState({ result: {}, fields: {} }, () => {
                                        this.setState({ result: { ...res }, fields: { ...fild } }, () => resolve())
                                    })
                                }

                                return
                            }

                            res['Количество направляющих'] = length * 100 / 40 +
                                (Math.round(length * 100 / 40) == length * 100 / 40 ? 0 :
                                    length * 100 % 40 / 40 > 0.5 ? 1 : 0
                                )

                            res['Количество направляющих'] -= res['Количество направляющих'] % 1

                            res['Шаг складки'] = (length * 100 / res['Количество направляющих']).toFixed(2)

                            this.setState({ result: {}, fields: {} }, () => {
                                this.setState({ result: { ...res }, fields: { ...fild } }, () => resolve())
                            })

                        })

                    },
                },
                ...fieldSetting('Сторона мотора', [['Слева', 0], ['Справа', 0], ['Тандем', this.props.TEXTS['Зимний сад - Тандем'].TEXT]]),
                ...fieldSetting('Сторона раздвижки', [['К мотору', 0], ['От центра', this.props.TEXTS['Зимний сад - Сторона раздвижки От центра'].TEXT]], 'раздвижки стандартная'),
                ...this.GnutieDugovoe(this.props.TEXTS['Зимний сад - Дуговое гнутие'].TEXT),
                'Крепление': {
                    group: FieldGroups.Additional,
                    onShow: (props) => {
                        return new Promise((resolve, reject) => {
                            let length = getWidth(this.state.result['Длина (мм)'])
                            if (props.changedField != 'Длина (мм)' || length == null || length == '') {
                                resolve()
                                return
                            }

                            let fild = { ...{ ...this.state.fields } }
                            let res = { ...{ ...this.state.result } }

                            fild['Крепление'].values['На выносных кронштейнах'].coast = Math.round(length * this.props.TEXTS['Зимний сад - кронштейн'].TEXT)

                            if (res['Крепление'] == 'На выносных кронштейнах')
                                res['_Крепление'] = fild['Крепление'].values['На выносных кронштейнах'].coast

                            this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                        })
                    },
                    values: {
                        "Стандартное (в проем)": { coast: 0 },
                        "На выносных кронштейнах": { coast: 0 }
                    }
                },
                ...DopGlaider(value => value * this.props.TEXTS['Зимний сад - дополнительный глайдер'].TEXT),
            }


        }




        this.state.fields = {
            'Вид карниза': {

                values: {
                    'Моторизированный': {
                        childFields: {

                            'Тип карниза': {
                                values: {
                                    'Раздвижные карнизы': {
                                        childFields: {
                                            ...this.dataFields['Раздвижные карнизы'],
                                            ...Pokraska(),
                                        },

                                    },
                                    'Римские шторы': {
                                        childFields: { ...this.dataFields['Римские шторы'] }
                                    },
                                    'Рулонные шторы': {
                                        childFields: { ...this.dataFields['Рулонные шторы'] }
                                    },

                                    'Зимний сад': {
                                        childFields: {
                                            ...this.dataFields['Зимний сад'],
                                            ...Pokraska(),
                                        },
                                    },
                                }
                            },
                            'Пульты и кнопки': {
                                disabled: true,
                                values: {
                                    'Да': {
                                        childFields: {}
                                    },
                                    'Нет': {},
                                },
                                onShow: (props) => {
                                    return new Promise((resolve, reject) => {
                                        let fild = { ...{ ...this.state.fields } }
                                        let res = { ...{ ...this.state.result } }
                                        if (this.state.nowLoading == 0 && res['Пульты и кнопки'] == null) {
                                            res['Пульты и кнопки'] = 'Нет'
                                            this.setState({ fields: {}, result: {} }, () => {
                                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                            })
                                            return
                                        }

                                        if (this.state.nowLoading != 0 || props.changedField == 'Бренд' || props.changedField == 'Тип карниза' || props.changedField == 'Вид карниза') {





                                            let deletData = () => {

                                                Object.keys(fild).forEach(f => {
                                                    if (f.indexOf('PULT') != -1) {
                                                        delete (fild[f])
                                                        delete (res[f])
                                                        delete (res['_' + f])
                                                    }
                                                })

                                                return { fild, res }
                                            }



                                            if (this.state.nowLoading == 0 && (res['Бренд'] == null || props.changedField == 'Тип карниза' || props.changedField == 'Вид карниза')) {
                                                if (res['Пульты и кнопки'] == 'Да' && this.state.nowLoading == 0) {

                                                    this.lastBrend = null
                                                    let tmp = deletData()
                                                    fild = { ...tmp.fild }
                                                    res = { ...tmp.res }

                                                    res['Пульты и кнопки'] = 'Нет'

                                                    fild['Пульты и кнопки'].disabled = true
                                                    fild['Пульты и кнопки'].values['Да'] = { childFields: {} }

                                                    this.setState({ fields: {}, result: {} }, () => {
                                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                                    })
                                                }
                                            } else {
                                                fild['Пульты и кнопки'].disabled = false

                                                if (this.lastBrend != res['Бренд']) {
                                                    this.lastBrend = res['Бренд']
                                                    //мы изменили бренд
                                                    let tmp = deletData()
                                                    fild = tmp.fild
                                                    res = tmp.res

                                                    let childFields = {}
                                                    let brend = res['Бренд'] == null ? null : res['Бренд'].replace('NOVOmini', 'NOVO')



                                                    if (res['Бренд'] != null)
                                                        Pults()[brend].forEach(p => {
                                                            childFields[p.type + " " + p.title + " (шт)"] = {
                                                                parent: { 'Пульты и кнопки': 'Да' },
                                                                group: FieldGroups.Pults,
                                                                onShow: (value) => {
                                                                    let fild = { ...{ ...this.state.fields } }
                                                                    let res = { ...{ ...this.state.result } }

                                                                    return new Promise((resolve, reject) => {
                                                                        if (res[p.type + " " + p.title + " (шт)"] == 0) {
                                                                            delete (res[p.type + " " + p.title + " (шт)"])
                                                                            delete (res['_' + p.type + " " + p.title + " (шт)"])

                                                                            this.setState({ fields: {}, result: {} }, () => {
                                                                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                                                            })
                                                                        } else resolve()
                                                                    })
                                                                },
                                                                values: {
                                                                    ...(() => {
                                                                        let result = {}
                                                                        for (let i = 0; i < 100; i++) {
                                                                            result[i + ''] = { coast: i * p.coast }
                                                                        }
                                                                        return result
                                                                    })()
                                                                }
                                                            }
                                                        })
                                                    fild['Пульты и кнопки'].values['Да'] = { childFields: {} }
                                                    fild['Пульты и кнопки'].values['Да'].childFields = { ...childFields }

                                                    if (res['Пульты и кнопки'] == 'Да') {
                                                        fild = {
                                                            ...fild,
                                                            ...childFields
                                                        }
                                                    }
                                                    this.setState({ fields: {}, result: {} }, () => {
                                                        this.setState({ fields: { ...fild }, result: { ...res } }, () => {

                                                            resolve()
                                                        })
                                                    })
                                                }
                                            }
                                        } else resolve()
                                    })
                                },
                            }
                        }
                    },
                    'Ручной': {
                        childFields: {

                            'Тип карниза': {
                                values: {
                                    'Римские шторы': {
                                        childFields: {
                                            'Длина (мм)': {
                                                type: 'number',
                                                max: 3 * 1000,
                                                MathCoast: (value) => getWidthCoast(value,
                                                    this.funcValues({
                                                        start: 1300, min: 0.4, max: 3,
                                                        func: (prev, step, i) => prev + 80
                                                    })
                                                )
                                            },

                                            'Высота (мм)': {
                                                type: 'number',
                                                max: 3 * 1000,
                                                MathCoast: (value) => getWidthCoast(value,
                                                    this.funcValues({
                                                        start: 0, min: 0.4, max: 3,
                                                        func: (prev, step, i) => 0
                                                    })
                                                )
                                            },


                                            ...Kreplenie(),
                                            ...FiberGlass(),
                                            'Цепочка': {
                                                values: {
                                                    'Пластиковая': { coast: 0 },
                                                    'Металлическая': { coast: 320 }
                                                }
                                            },
                                            ...Ytajelitel(),
                                            'Управление': {
                                                values: {
                                                    'Слева': {},
                                                    'Справа': {}
                                                }
                                            },
                                        },
                                    },
                                    'Раздвижные карнизы': {
                                        childFields: {
                                            'Длина (мм)': {
                                                type: 'number',
                                                max: 20 * 1000,
                                                MathCoast: (value) => getWidthCoast(value,
                                                    this.funcValues({
                                                        start: 70, min: 0.2, max: 20,
                                                        func: (prev, step, i) => prev + this.props.TEXTS['Ручной карниз - Раздвижные шторы - шаг цены'].TEXT
                                                    })
                                                )
                                            },

                                            ...this.GnutieDugovoe(this.props.TEXTS['Дуговое гнутие - Мото карнизы раздвижные'].TEXT),
                                            ...Kreplenie(),
                                            ...DopGlaider(value => value * 8),
                                            ...Pokraska(),
                                        },

                                    },
                                }
                            },
                            'Бренд': {
                                disabled: true,
                                onShow: (props) => {
                                    return new Promise((resolve, reject) => {
                                        if (this.state.result['Бренд'] == null) {
                                            let res = { ...{ ...this.state.result } }
                                            let fild = { ...{ ...this.state.fields } }
                                            res['Бренд'] = 'ПрофКарниз'
                                            this.setState({ fields: {}, result: {} }, () => {
                                                this.setState({ fields: { ...fild }, result: { ...res } }, () => resolve())
                                            })
                                        } else resolve()
                                    })
                                },
                                values: {
                                    'ПрофКарниз': {}
                                }
                            }
                        }
                    }
                }
            },
            "Количество карнизов (шт)": {
                group: FieldGroups.Additional,
                onShow: props => {
                    return new Promise((resolve, reject) => {
                        let res = { ...{ ...this.state.result } }
                        let fild = { ...{ ...this.state.fields } }

                        if ((props.changedField == 'Тип карниза' || props.changedField == 'Вид карниза')
                            && this.state.nowLoading == 0 &&
                            (
                                this.previosTypeCarn != res['Тип карниза'] ||
                                this.previosVidCarn != res['Вид карниза']
                            ) && this.previosTypeCarn != null && this.previosVidCarn != null) {

                            this.previosTypeCarn = res['Тип карниза']
                            this.previosVidCarn = res['Вид карниза']

                            if (res['Количество карнизов (шт)'] != 1) {
                                res['Количество карнизов (шт)'] = 1
                                this.setState({ result: {}, fields: {} }, () => {
                                    this.setState({ result: { ...res }, fields: { ...fild } }, () => {
                                        this.setState({ result: {}, fields: {} }, () => {
                                            this.setState({ result: { ...res }, fields: { ...fild } }, () => resolve())
                                        })
                                    })
                                })
                            } else resolve()
                        } else {
                            this.previosTypeCarn = res['Тип карниза']
                            this.previosVidCarn = res['Вид карниза']
                            resolve()
                        }
                    })
                },
                values: {
                    ...(() => {
                        let result = {}
                        for (let i = 1; i < 100; i++) {
                            result[i + ''] = {}
                        }
                        return result
                    })()
                }
            },
            "Наценка (%)": {
                group: FieldGroups.Additional,
                type: 'number',
                MathCoast: (val) => 0
            },
            /*
            "Монтаж":{
                group:FieldGroups.Additional,
                values:{
                    'Да':{},
                    'Нет':{},
                }
            },
            "Доставка":{
                group:FieldGroups.Additional,
                values:{
                    'Самовывоз':{},
                    'Доставка':{},
                    'Доставка ТК':{},
                }
            },
            */


        }

        this.startFields = { ...{ ...this.state.fields } }




        console.warn(this.dataFields)

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ nowLoading: 2 })

            if (this.ID_ORDER_ITEM != null && this.ID_ORDER != null) {
                GF.API_task({
                    operation: this.USER_TOKEN == null ? 'getOrderItem' : 'getOrderItem_noLogin',
                    block: 'Calc',
                    ID_USER: this.ID_USER || this.props.ID_USER,
                    ID_ORDER: this.ID_ORDER,
                    ID_ORDER_ITEM: this.ID_ORDER_ITEM,
                    USER_TOKEN: this.USER_TOKEN,
                }).then(async data => {
                    data.FIELDS_DATA = JSON.parse(data.ORDER_ITEM.FIELDS_DATA)
                    data.RESULT = JSON.parse(data.ORDER_ITEM.RESULT)


                    let fild = { ...{ ...this.state.fields } }
                    let res = { ...{ ...this.state.result } }
                    let ussed = []

                    let iterations = 0
                    while (Object.keys(data.ORDER_ITEM.FIELDS_DATA).length > ussed.length) {

                        await (async () => {
                            await new Promise(r1 => {
                                iterations++
                                if (iterations > 200) {
                                    r1()
                                }
                                Object.keys(data.FIELDS_DATA).forEach(async (dataKey, r1_i) => {

                                    if (ussed.findIndex(u => u == dataKey) == -1) {

                                        let parent = Object.keys(data.FIELDS_DATA[dataKey])
                                        parent = parent == null || parent.length == 0 ? null : parent[0]//родительское поле, которое создало это поле
                                        let parentValue = parent == null ? null : data.FIELDS_DATA[dataKey][parent]//значение родителя, которое привело к созданию этого поля

                                        await new Promise(r2 => {
                                            Object.keys(fild).forEach(async (visibleField, r2_i) => {


                                                if (visibleField == parent) {//если нашли того самого родителя, то работаем с ним

                                                    if (fild[visibleField].type != 'number' && fild[visibleField].values != null) {

                                                        await new Promise(r3 => {
                                                            Object.keys(fild[visibleField].values).forEach(async (valueKey, r3_i) => {
                                                                if (valueKey == parentValue) {//Если это то самое выбранное значение - выбираем его

                                                                    if (res[parent] == null) {
                                                                        res[parent] = valueKey
                                                                        if (fild[visibleField].values[valueKey].coast != null)
                                                                            res['_' + parent] = fild[visibleField].values[valueKey].coast * 1

                                                                        if (fild[visibleField].values[valueKey].childFields != null) {// добавляем дочерние поля
                                                                            await new Promise(r4 => {
                                                                                Object.keys(fild[visibleField].values[valueKey].childFields).forEach((childe, r4_i) => {
                                                                                    fild[visibleField].values[valueKey].childFields[childe].parent = {}
                                                                                    fild[visibleField].values[valueKey].childFields[childe].parent[visibleField] = data.RESULT[visibleField]
                                                                                    if (r4_i == Object.keys(fild[visibleField].values[valueKey].childFields).length - 1) r4()
                                                                                })
                                                                            })
                                                                            fild = {
                                                                                ...fild,
                                                                                ...fild[visibleField].values[valueKey].childFields
                                                                            }
                                                                        }

                                                                        await new Promise((resolve, reject) => {
                                                                            this.setState({ fields: {}, result: {} }, () => {
                                                                                this.setState({ fields: { ...fild }, result: { ...res } }, () => {
                                                                                    resolve()
                                                                                })
                                                                            })
                                                                        })



                                                                        await new Promise((resolve1) => {
                                                                            Object.keys(fild).forEach(async (key, i) => {
                                                                                if (fild[key].onShow != null) {
                                                                                    await new Promise(resolve =>
                                                                                        setTimeout(() => resolve(), 1)
                                                                                    )
                                                                                    await fild[key].onShow({ changedField: parent })
                                                                                }

                                                                                if (i == Object.keys(fild).length - 1) {
                                                                                    resolve1()
                                                                                }
                                                                            })
                                                                        })






                                                                        fild = { ...{ ...this.state.fields } }
                                                                        res = { ...{ ...this.state.result } }

                                                                    }

                                                                }

                                                                if (r3_i == Object.keys(fild[visibleField].values).length - 1) r3()
                                                            })
                                                        })
                                                    } else if (fild[visibleField].type == 'number') {



                                                        if (res[parent] == null) {
                                                            res[parent] = parentValue * 1
                                                            if (fild[visibleField].MathCoast != null)
                                                                res['_' + parent] = fild[visibleField].MathCoast(parentValue) * 1
                                                            else
                                                                res['_' + parent] = parentValue * 1

                                                            await new Promise((resolve, reject) => {
                                                                this.setState({ fields: {}, result: {} }, () => {
                                                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => {
                                                                        resolve()
                                                                    })
                                                                })
                                                            })

                                                            Object.keys(fild).forEach(async key => {
                                                                if (fild[key].onShow != null) {
                                                                    await new Promise(resolve =>
                                                                        setTimeout(() => resolve(), 1)
                                                                    )
                                                                    await fild[key].onShow({ changedField: parent })
                                                                }
                                                            })
                                                            fild = { ...{ ...this.state.fields } }
                                                            res = { ...{ ...this.state.result } }
                                                        }
                                                    }
                                                }

                                                if (r2_i == Object.keys(fild).length - 1) r2()
                                            })
                                        })
                                        //нужно добавить сами значения



                                        if (data.RESULT[dataKey] != null && fild[dataKey] != null) {//если в поле что то было выбрано и оно уже существует
                                            ussed.push(dataKey)
                                            res[dataKey] = data.RESULT[dataKey]
                                            if (fild[dataKey].values != null && fild[dataKey].type != 'number') {
                                                try {
                                                    if (fild[dataKey].values[data.RESULT[dataKey]].coast != null) {
                                                        if (dataKey != 'Цена ткани') {
                                                            res['_' + dataKey] = fild[dataKey].values[data.RESULT[dataKey]].coast * 1
                                                        }
                                                    }
                                                } catch (e) { }
                                                try {
                                                    if (fild[dataKey].values[data.RESULT[dataKey]].childFields != null) {
                                                        Object.keys(fild[dataKey].values[data.RESULT[dataKey]].childFields).forEach(childe => {
                                                            fild[dataKey].values[data.RESULT[dataKey]].childFields[childe].parent = {}
                                                            fild[dataKey].values[data.RESULT[dataKey]].childFields[childe].parent[dataKey] = data.RESULT[dataKey]
                                                        })
                                                        fild = {
                                                            ...fild,
                                                            ...fild[dataKey].values[data.RESULT[dataKey]].childFields
                                                        }
                                                    }
                                                } catch (e) { }
                                            } else {
                                                if (fild[dataKey].type == 'number') {
                                                    if (dataKey != 'Цена ткани') {
                                                        res['_' + dataKey] = fild[dataKey].MathCoast == null ?
                                                            data.RESULT[dataKey] * 1 :
                                                            fild[dataKey].MathCoast(data.RESULT[dataKey]) * 1
                                                    }
                                                }
                                            }

                                            await new Promise((resolve, reject) => {
                                                this.setState({ fields: {}, result: {} }, () => {
                                                    this.setState({ fields: { ...fild }, result: { ...res } }, () => {
                                                        resolve()
                                                    })
                                                })
                                            })

                                            await new Promise(r5 => {
                                                Object.keys(fild).forEach(async (key, r5_i) => {

                                                    if (fild[key].onShow != null) {

                                                        await fild[key].onShow({ changedField: dataKey })
                                                    }
                                                    if (r5_i == Object.keys(fild).length - 1) r5()
                                                })
                                            })

                                            fild = { ...{ ...this.state.fields } }
                                            res = { ...{ ...this.state.result } }
                                        }
                                    }

                                    if (r1_i == Object.keys(data.FIELDS_DATA).length - 1) r1()
                                })


                            })
                        })()

                        iterations++
                        if (iterations > 200) {
                            break
                        }
                    }



                    this.setState({ Order: { ...data.ORDER }, Self: { ...data.ORDER_ITEM }, fields: {}, result: {} }, () => {
                        this.setState({ fields: { ...fild }, result: { ...res } }, () => {


                            setTimeout(() => {

                                this.setState({ nowLoading: 1 })
                                setTimeout(() => {
                                    this.setState({ nowLoading: 0 }, () => {
                                        setTimeout(() => {
                                            if (this.recalc == 1) {
                                                this.SaveBtn.click()
                                            }
                                        }, 500)
                                    })
                                }, 300)
                            }, 200)

                        })
                    })

                })
            } else {
                setTimeout(() => {
                    this.setState({ nowLoading: 1 })
                    setTimeout(() => {
                        this.setState({ nowLoading: 0 })
                    }, 1000)
                }, 1000)
            }

            Object.keys(this.state.fields).forEach(async allKey => {
                if (this.state.fields[allKey].onShow != null) {
                    await this.state.fields[allKey].onShow({ changedField: null })
                }
            })

        }, 10)


    }

    checkReqv() {
        let fields = { ...{ ...this.state.fields } }
        let result = { ...{ ...this.state.result } }

        if (result['Количество карнизов (шт)'] == null || result['Количество карнизов (шт)'] == '')
            result['Количество карнизов (шт)'] = 1

        if (result['Наценка (%)'] == null || result['Наценка (%)'] == '')
            result['Наценка (%)'] = 0




        const canBeEmpty = ['Тип гибки', 'Дополнительный глайдер', 'Покраска (базовая стоимость)', 'Фибергласовые вставки', 'Утяжелитель']

        let errorFields = []
        Object.keys(fields).forEach(field => {
            if (result[field] == null && canBeEmpty.findIndex(c => c == field) == -1 && field.indexOf('PULT') == -1 && fields[field].disabled !== true || (
                (field == 'Длина (мм)' || field == 'Ширина (мм)' || field == 'Высота (мм)') &&
                result[field] < 100
            )) {
                errorFields.push(field)
            }
        })

        return errorFields
    }

    Save(props) {
        if (!this.canSave) return
        this.canSave = false



        let fields = { ...{ ...this.state.fields } }
        let result = { ...{ ...this.state.result } }

        let ID_ORDER_ITEM = this.ID_ORDER_ITEM

        let ID_ORDER = this.ID_ORDER

        let errorFields = this.checkReqv()


        this.setState({ errorFields })

        if (errorFields.length > 0) {
            setTimeout(() => {
                alert('Заполните все видимые поля')
            }, 300)

            this.canSave = true
            return
        }

        let fieldsData = {}

        Object.keys(fields).forEach(key => {
            fieldsData[key] = fields[key].parent == null ? {} : fields[key].parent
        })

        GF.API_task({
            operation: this.USER_TOKEN == null ? 'createUpdateOrderItem' : 'createUpdateOrderItem_noLogin',
            block: 'Calc',
            ID_ORDER,
            ID_ORDER_ITEM,
            PAINTING: props.painting,
            SUM_PULTS: props.sumPults,
            USER_TOKEN: this.USER_TOKEN,
            ORDERS_COUNT: props.orders_count,
            DOP_COAST: props.DOP_COAST,
            TAX_COAST: props.TaxCoast,
            TAX: props.Tax,
            ONE_COAST: props.ONE_COAST,
            RESULT: JSON.stringify(result),
            FIELDS_DATA: JSON.stringify(fieldsData)
        }).then(res => {


            if (this.USER_TOKEN != null)
                document.location.href = Constants.serverMain + `calk/saved/?ID_ORDER=${res.ORDER.ID}&ID_ORDER_ITEM=${res.ORDER_ITEM.ID}`
            else {
                this.props.getORDERS(() => {
                    setTimeout(() => {
                        this.props.callBack(res)
                    }, 100)
                })

            }
        }).catch(e => {
            console.error(e)
            alert('Ошибка: ' + e)
            this.canSave = true
        })


    }



    getDeep(obj, path = [], value) {
        if (path.length > 0) {
            let key = path[0]

            if (path.length > 1) {
                if (obj[key] == null)
                    obj[key] = {}
                this.getDeep(obj[key], lodash.takeRight(path, path.length - 1), value)
            } else {
                obj[key] = value
            }
        }
    }

    deleteAllChildes(obj, tmp, result) {
        if (obj.values != null)
            Object.keys(obj.values).forEach(key => {

                if (obj.values[key].childFields != null)
                    Object.keys(obj.values[key].childFields).forEach(keyChilde => {

                        delete (tmp[keyChilde])
                        delete (result[keyChilde])
                        delete (result['_' + keyChilde])

                        this.deleteAllChildes(obj.values[key].childFields[keyChilde], tmp, result)
                    })
            })

        return { tmp, result }
    }

    funcValues(props) {
        let values = {}
        let steped = 0
        let current = props.start

        props.min = props.min
        props.max = props.max

        for (let i = props.min; i <= props.max + 0.1; i += props.step == null ? 0.1 : props.step) {
            if (i == props.min) {
                values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)] = {
                    coast: props.start
                }

            } else {
                let next = props.func(current, steped, i.toFixed(props.toFixed == null ? 1 : props.toFixed))
                values[(i).toFixed(props.toFixed == null ? 1 : props.toFixed)] = {
                    coast: next,
                }
                current = next
            }

            steped++
        }

        return values
    }


    renderCalk() {
        const { state, props } = this
        const { fields } = state
        const { Self, Order } = state





        let res = []
        const funcSort = (field, localRes_itBeRenderen = []) => {
            let localRes = []

            if (localRes_itBeRenderen.findIndex(r => r.label == field) == -1)
                localRes.push({
                    label: field,
                    group: fields[field].group
                })//Добавил первое поле


            if (fields[field].childFields != null) {
                Object.keys(fields[field].childFields).forEach(childe => {

                    if (fields[childe] != null && localRes_itBeRenderen.findIndex(r => r.label == childe) == -1)
                        localRes.push(...funcSort(childe, [...localRes, ...localRes_itBeRenderen]))//вызываю рендер для всех детей

                })
            }
            if (fields[field].values != null) {
                Object.keys(fields[field].values).forEach(value => {

                    if (fields[field].values[value].childFields != null) {
                        Object.keys(fields[field].values[value].childFields).forEach(childe => {

                            if (fields[childe] != null && localRes_itBeRenderen.findIndex(r => r.label == childe) == -1)
                                localRes.push(...funcSort(childe, [...localRes, ...localRes_itBeRenderen]))//вызываю рендер для всех детей

                        })
                    }

                })
            }

            return localRes
        }

        let fieldsKeys = Object.keys(fields)

        fieldsKeys.forEach(f => {
            if (res.findIndex(r => r.label == f) == -1) //если поле еще не рендерится, то запустить ег опоиск
                res.push(...funcSort(f))
        })

        let resFilds = {}
        Object.keys(FieldGroups).forEach(key => {
            let keyTitle = FieldGroups[key]
            if (key == 'Simple') {
                resFilds[keyTitle] = res.filter(r => r.group == null || r.group == keyTitle)
            } else
                resFilds[keyTitle] = res.filter(r => r.group == keyTitle)
        })





        return (<>
            <Alert icon={false} className='mb-3' color='warning'>
                Обратите внимание, что это предварительный расчет.
                Итоговую стоимость необходимо согласовать с менеджером
            </Alert>

            {this.props.user.ROLE == 'INST' ? null :
                Self.ID != null && Order.ID_STATUS != 11 ?
                    <Alert className='mb-3' color='warning'>
                        Заказ отправлен на оформление вашему менеджеру. Изменение невозможно
                    </Alert>
                    : <>
                        <Alert icon={false} className='mb-3'>
                            На стоимость могут влиять такие параметры как покраска, доставка и т.д. стоимость которых зависит индивидуально от каждого заказа
                        </Alert>

                        <Button onClick={() => {
                            this.setState({ result: {}, fields: {}, errorFieldsDescr: {}, errorFields: [] }, () => {
                                this.setState({ result: { 'Количество карнизов (шт)': 1 }, fields: { ...this.startFields } })
                            })
                        }} color='success' style={{
                            float: 'right'
                        }}>Очистить все поля</Button>
                    </>
            }
            {Object.keys(resFilds).map(group => {
                return <>
                    {resFilds[group].length == 0 ? null : <>
                        <h4>{group}</h4>
                        {resFilds[group].map(keyField => {
                            let key = keyField.label
                            if (key == 'Наценка (%)' && this.props.user?.ROLE != 'ADMN' && this.props.user?.ROLE != 'CPRT' && this.props.user?.ROLE != 'PRTN') return null

                            return (<>
                                {(!!this.state.fields[key].min || !!this.state.fields[key].max) && !this.state.fields[key].disabled &&
                                    <Stack direction='row' style={{
                                        marginTop: 5,
                                        marginBottom: -10,
                                        width: 'max-content',
                                        background: 'color(srgb 0.8366 0.884 0.94)',
                                        borderRadius: 4,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                        padding: '5px 10px',
                                    }}>
                                        {!!this.state.fields[key].min && <Label>min {this.state.fields[key].min / 1000}м</Label>}
                                        {!!this.state.fields[key].min && !!this.state.fields[key].max && <div style={{ width: 10 }} />}
                                        {!!this.state.fields[key].max && <Label>max {this.state.fields[key].max / 1000}м</Label>}
                                    </Stack>
                                }
                                <SelectView closePopUp={() => this.props.closePopUp()} openPopUp={(view) => this.props.openPopUp(view)} Ref={this.inputFields} state={state} label={key} key={key} type={fields[key].type} disabled={
                                    fields[key].disabled || (Order?.ID_STATUS != null && Order?.ID_STATUS != 11)
                                } value={state.result[key]} onChange={value => {
                                    let errorFields = [...[...this.state.errorFields]]
                                    let errorFieldsDescr = { ...this.state.errorFieldsDescr }
                                    let haveError = false

                                    if (key == 'Длина (мм)' || key == 'Ширина (мм)' || key == 'Высота (мм)') {
                                        if (value < 100) {
                                            errorFields.push(key)
                                            haveError = true
                                            errorFieldsDescr[key] = 'Проверьте корректность размера в миллиметрах'
                                            this.setState({ errorFields, errorFieldsDescr })

                                        }
                                    }


                                    let func = () => {
                                        let tmp = this.state.fields

                                        let result = GF.ObjectCopy(this.state.result)
                                        this.deleteAllChildes(tmp[key], tmp, result)

                                        result = GF.changeParametr(result, key, value)
                                        if (value != -1 && tmp[key].values != null && tmp[key].values[value].coast != null) {//если у выбранного параемтра есть цена, то записать ее
                                            result = GF.changeParametr(result, "_" + key, tmp[key].values[value].coast)
                                        }



                                        if (value == -1) {
                                            delete (result[key])
                                            delete (result['_' + key])
                                        } else if (!!fields[key].MathCoast) {
                                            result['_' + key] = fields[key].MathCoast(value)
                                        }




                                        if (result[key] != null && !haveError) {
                                            let indexError = errorFields.findIndex(f => f == key)
                                            if (indexError != -1)
                                                errorFields.splice(indexError, 1)

                                            try {
                                                delete (errorFieldsDescr[key])
                                            } catch { }
                                        }

                                        this.setState({ result: {}, fields: {} }, () => {
                                            this.setState({ result, fields: tmp, errorFields, errorFieldsDescr }, () => {

                                                if (value != -1 && tmp[key].values != null && tmp[key].values[value].childFields != null) {
                                                    Object.keys(tmp[key].values[value].childFields).forEach((chkey, i) => {
                                                        tmp[key].values[value].childFields[chkey].parent = {}
                                                        tmp[key].values[value].childFields[chkey].parent[key] = value
                                                    })
                                                    tmp = {
                                                        ...tmp,
                                                        ...tmp[key].values[value].childFields
                                                    }
                                                }

                                                this.setState({ fields: tmp }, () => {

                                                    Object.keys(this.state.fields).forEach(async allKey => {
                                                        if (this.state.fields[allKey].onShow != null) {
                                                            await new Promise(resolve =>
                                                                setTimeout(() => resolve(), 1)
                                                            )
                                                            await this.state.fields[allKey].onShow({ changedField: key })
                                                        }
                                                    })
                                                    console.error(this.state)
                                                })
                                            })
                                        })

                                    }

                                    if (fields[key].values != null && fields[key].values[value] != null && fields[key].values[value].onSelect != null) {
                                        fields[key].values[value].onSelect(key, value, func)
                                    } else {
                                        func()
                                    }



                                }} items={
                                    fields[key].values == null ? [] :
                                        Object.keys(fields[key].values).map(ItemKey =>
                                            ItemKey
                                        )
                                } />
                            </>)
                        })}
                        <hr />
                    </>}
                </>
            })}



            {(() => {
                if (this.props?.user?.ROLE == 'INST')
                    return null


                let sumKarniz = 0
                let sumDop = 0
                let sumPults = 0
                let needPainting = false
                let painting = 0

                let countKarniz = state.result['Количество карнизов (шт)']
                countKarniz = countKarniz == null || countKarniz == '' ? 1 : countKarniz

                let Tax = state.result['Наценка (%)'] == null ? 0 : state.result['Наценка (%)'] * 1


                Object.keys(state.result).forEach(key => {
                    if (key[0] == '_') {//если это цена за что то

                        if (key.substring(1, 5) == 'PULT')
                            sumPults += state.result[key]
                        else
                            if (this.state.fields[key.substring(1)].group == FieldGroups.Additional)
                                sumDop += state.result[key]
                            else
                                sumKarniz += state.result[key]

                    }
                })



                if (state.result['Покраска (базовая стоимость)'] == 'Да') {
                    needPainting = true
                    sumDop -= state.result['_Покраска (базовая стоимость)']
                    painting = state.result['_Покраска (базовая стоимость)'] + (countKarniz - 1) * 1000
                }



                if ((this.state.result['Тип управления/мотора'] == null && this.state.result['Вид карниза'] != 'Ручной') ||
                    this.state.result['Длина (мм)'] == null
                ) {
                    sumKarniz = 0
                }


                return (<>
                    <div style={{
                        display: 'block',
                        marginBottom: this.USER_TOKEN != null ? 20 : 120,
                        marginTop: -10

                    }}>
                        {sumKarniz > 0 ? null : <small style={{
                        }}>Заполните поля, чтобы посчитать ориентировочную стоимость</small>}
                    </div>

                    <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 100,
                        overflow: 'hidden',
                        height: 'auto',

                        ...(this.USER_TOKEN != null ? {
                            position: 'relative',
                        } : {})
                    }}>
                        <div style={{
                            background: ' rgba(255,255,255,0.6)',
                            WebkitBackdropFilter: 'blur(10px)',
                            backdropFilter: 'blur(10px)',

                            width: '100%',
                            zIndex: 100,
                            borderTop: '1px solid white',
                            padding: '0px 40px',
                            height: 'auto',
                            paddingTop: 16,
                            paddingBottom: 16,
                            boxShadow: '0px 0px 30px rgba(0,0,0,0.05)',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'space-between',
                            transition: '.5s ease-in-out',
                            transform: `translateY(${sumKarniz <= 0 ? 100 : 0}px)`,

                            ...(this.USER_TOKEN != null ? {
                                padding: 0,
                                paddingBottom: 20,
                                transform: null,
                                WebkitBackdropFilter: null,
                                backdropFilter: null,
                                flexDirection: 'column',
                                gap: 20,
                                height: 'auto',
                                boxShadow: null,
                                zIndex: null,
                                alignItems: 'flex-start'
                            } : {})
                        }}>
                            <div style={{
                                flex: this.USER_TOKEN != null ? null : 1,
                                width: this.USER_TOKEN != null ? 'max-content' : null,
                                paddingRight: 20
                            }}>
                                {sumKarniz <= 0 ? null :
                                    <p style={{
                                        fontSize: 12, margin: 0
                                    }}>Один карниз {GF.makeCoast(sumKarniz * (Tax / 100 + 1))}₽</p>
                                }

                                {(sumDop + painting) <= 0 ? null :
                                    <p style={{
                                        fontSize: 12, margin: 0
                                    }}>Дополнительно {GF.makeCoast((sumDop + painting) * (Tax / 100 + 1))}₽</p>
                                }

                                {sumPults == 0 ? null : <p style={{
                                    fontSize: 12, margin: 0
                                }}>Пульты {GF.makeCoast(sumPults * (Tax / 100 + 1))}₽</p>}

                                {sumKarniz == 0 ? null : <>
                                    {this.USER_TOKEN != null ? null :
                                        <hr className='mt-2 mb-2' />}
                                    <h3 style={{
                                        fontSize: 15,
                                        margin: 0
                                    }}>Итого {GF.makeCoast(((sumKarniz + sumDop) * countKarniz + painting + sumPults) * (Tax / 100 + 1))}₽
                                        <small style={{ color: 'green', fontSize: 11, }}>
                                            {Tax == 0 ? '' : ' (' + GF.makeCoast(((sumKarniz + sumDop) * countKarniz + painting + sumPults) * (Tax / 100)) + '₽)'}
                                        </small>
                                    </h3>
                                </>
                                }
                            </div>

                            {Self.ID != null && Order.ID_STATUS != 11 ? null :
                                <Button ref={ref => this.SaveBtn = ref} color={this.checkReqv().length == 0 ? 'success' : 'warning'} onClick={() => this.Save({
                                    orders_count: countKarniz,
                                    ONE_COAST: Math.round(sumKarniz),
                                    DOP_COAST: Math.round(sumDop),
                                    sumPults,
                                    painting,
                                    TaxCoast: Math.round(sumKarniz / 100 * Tax),
                                    Tax
                                })} id='primaryButton'  >
                                    Сохранить расчет
                                </Button>}

                            <div style={{
                                marginBottom: this.USER_TOKEN != null ? 500 : 0
                            }} />
                        </div>
                    </div>
                </>)
            })()}
        </>)
    }





    render() {
        const { user, STATUSES, USERS } = this.props
        const { Self } = this.state

        if (Object.keys(this.props.TEXTS).length == 0) return <div />


        return (<div style={{
            transition: 'opacity 1s',
            opacity: this.state.nowLoading == 3 ? 0 : 1,
            //paddingLeft:this.USER_TOKEN!=null?15:0,
            //paddingRight:this.USER_TOKEN!=null?15:0
        }}>

            {this.renderCalk()}
            {this.state.nowLoading == 0 ? null :
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    position: 'fixed',
                    zIndex: 10000,
                    opacity: this.state.nowLoading <= 1 ? 0 : 1,
                    transition: 'opacity 1s ease .3s',
                    background: 'white',
                }}>
                    <LinearProgress style={{
                        height: 5
                    }} color='success' />
                </div>
            }
        </div>)

    }
}


function SelectView(props) {
    let state = { ...props.state }

    if (props.type == 'number')
        return (
            <TextField
                className='mt-2 w-100'
                margin='dense'
                variant="filled"
                size='small'
                error={state.errorFields.findIndex(i => i == props.label) != -1}
                helperText={state.errorFieldsDescr[props.label]}
                type='number'
                pattern="\d*"
                style={{
                    background: state.errorFields.findIndex(i => i == props.label) != -1 ? 'rgb(255, 232, 232)' : null
                }}
                InputLabelProps={{ shrink: true }}
                inputRef={ref => props.Ref[props.label] = ref}
                color={state.errorFields.findIndex(i => i == props.label) != -1 ? 'error' : 'success'}
                label={props.label.replace('PULT', '')}
                disabled={props.disabled}
                value={props.value}
                onFocus={() => {
                    try {
                        props.Ref[props.label].blur()
                    } catch (e) { }
                }}
                onClick={(e) => {
                    if (props.disabled) return

                    let value = prompt(props.label.replace('PULT ', ''), '')
                    value = value.replace(/[^0-9.,]/gm, '').replace(',', '.')
                    props.Ref[props.label].value = value


                    props.onChange(value)

                    try {
                        props.Ref[props.label].blur()
                    } catch (e) { }




                }}
                onChange={(e) => {

                    e.target.value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.')

                    props.onChange(e.target.value)
                }}
            />)
    else

        return (
            <FormControl fullWidth className='mt-2' key={props.key} variant="filled" size='small' color='success'>
                <InputLabel variant="filled" size='small'
                    htmlFor={props.label}>{props.label.replace('PULT', '')}</InputLabel>
                <Select
                    size='small'
                    inputProps={{
                        name: props.label,
                        id: props.label
                    }}
                    value={props.value}
                    label={props.label.replace('PULT', '')}
                    variant="filled"
                    style={{
                        background: state.errorFields.findIndex(i => i == props.label) != -1 ? 'rgb(255, 232, 232)' : null
                    }}
                    error={state.errorFields.findIndex(i => i == props.label) != -1}
                    color={state.errorFields.findIndex(i => i == props.label) != -1 ? 'error' : 'success'}
                    native
                    in
                    defaultValue={-1}
                    defaultChecked={-1}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(e.target.value)}
                >
                    <option value={-1}>---</option>
                    {props.items.map(i =>
                        <option value={i.value == null ? i : i.value}>{i.label == null ? i : i.label}</option>
                    )}
                </Select>
            </FormControl>)
}




const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        closePopUp: (arg) => dispatch(closePopUp(arg)),
        getORDERS: (callBack) => dispatch(getORDERS(callBack)),
    };
};


const mapStateToProps = (store) => {

    return {

        user: store.user.data,
        POPUPS: store.page.POPUPS,
        TEXTS: store.page.TEXTS,
        STATUSES: store.page.STATUSES,
        USERS: store.page.USERS,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index)

